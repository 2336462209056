import React, { memo } from "react";
import Button from "../../../components/ui/Button/Button";
import { SvgIcon } from "../../../components/ui/SvgIcon/SvgIcon";
import styles from "../OfferSale.module.scss";

import langs from "../../../common/services/languageService";
const { labels } = langs;

interface SaleConfirmProps {
  onSubmit: () => void;
}

const SaleConfirm: React.FC<SaleConfirmProps> = ({ onSubmit }) => {
  return (
    <>
      <div style={{ alignItems: "center" }}>
        <span>{labels.CustomerAlreadyRegistered()}</span>
      </div>
      <div className={styles.saleConfirmation}>
        <div>
          <SvgIcon icon="circleCheck" animation height="150px" customColor="green" />
        </div>
        <div>{labels.IConfirmTheSaleOfTheOffer()}</div>
      </div>
      <Button onClick={onSubmit}>{labels.Confirm().toLowerCase()}</Button>
    </>
  );
};

export default memo(SaleConfirm);
