import * as signalR from "@microsoft/signalr";

const hubUrl = (window as any).config.hubUrl;

class Connector {
  private connection: signalR.HubConnection;
  public events: (onCompleteRegistration: () => void, onTerminateRegistration: () => void) => void;
  static instance: Connector;

  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl)
      .withAutomaticReconnect()
      .build();
    this.connection.start().catch(err => document.write(err));
    this.events = (onCompleteRegistration, onTerminateRegistration) => {
      this.connection.on("RegistrationCompleted", () => {
        onCompleteRegistration();
      });
      this.connection.on("TerminateRegistration", () => {
        onTerminateRegistration();
      });
    };
  }

  public cancelRegistration = (transactionId: string) => {
    this.connection
      .send("cancelRegistration", transactionId)
      .then(x => console.log("Registration canceled"))
  }

  public listenOnTransaction = (transactionId: string) => {
    this.connection.send("ListenOnRegistration", transactionId).then(x => console.log(`Transaction ${transactionId} started...`));
  }

  public static getInstance(): Connector {
    if (!Connector.instance)
      Connector.instance = new Connector();
    return Connector.instance;
  }
}

export default Connector.getInstance;