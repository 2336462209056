import React, { AnchorHTMLAttributes, HTMLAttributeAnchorTarget, ReactElement, Ref, memo } from "react";
import styles from "./Link.module.scss";
import { clsx } from 'clsx';

type LinkProps = {
	refs?: Ref<HTMLAnchorElement>;
	children: ReactElement | ReactElement[] | string;
    href?: string;
    className?: string;
} & AnchorHTMLAttributes<HTMLAnchorElement>

const Link: React.FC<LinkProps> = ({refs, children, className, ...props }) => {

	return (
		<>
		<a {...props} ref={refs} className={clsx(styles.link, className)} target={props.target} href={props.href} >
			{children}
		</a>
		</>
	);
};

export default memo(Link);
