import { Control } from 'react-hook-form'
import TextBoxField from '../../../components/form/TextBoxField'
import langs from '../../../common/services/languageService'
import SelectField from '../../../components/form/SelectField'
import { ProfileFormModes, ProfileValues } from '../Profile'
import { FieldModes } from '../../../common/consts/FieldModes'
import styles from './Individual.module.scss'

const { labels, validationMessages } = langs

type Props = {
  control: Control<ProfileValues, any>
  countries: []
  voivodeships: []
  taxOffices: []
  fieldMode: FieldModes
  formMode: ProfileFormModes
}

const defaultCountry = { value: 'Polska', id: 10001 }

const validationRules = {
  street: {
    required: validationMessages.Required(),
    maxLength: {
      value: 100,
      message: validationMessages.MaximumLength().replace('{0}', '100'),
    },
  },
  buildingNumber: {
    required: validationMessages.Required(),
    maxLength: {
      value: 100,
      message: validationMessages.MaximumLength().replace('{0}', '100'),
    },
  },
  premisesNumber: {
    maxLength: {
      value: 100,
      message: validationMessages.MaximumLength().replace('{0}', '100'),
    },
  },
  city: {
    required: validationMessages.Required(),
    maxLength: {
      value: 100,
      message: validationMessages.MaximumLength().replace('{0}', '100'),
    },
  },
  voivodeshipId: { required: validationMessages.Required() },
  commune: {
    required: validationMessages.Required(),
    maxLength: {
      value: 100,
      message: validationMessages.MaximumLength().replace('{0}', '100'),
    },
  },
  taxOfficeId: { required: validationMessages.Required() },
  postOffice: {
    required: validationMessages.Required(),
    maxLength: {
      value: 100,
      message: validationMessages.MaximumLength().replace('{0}', '100'),
    },
  },
  pesel: {
    required: validationMessages.Required(),
    validate: (val: string) => {
      if (!val || val.trim() === '' || val.length !== 11) return false
      var weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
      var sum = 0
      var controlNumber = parseInt(val.substring(10, 11))
      for (var i = 0; i < weight.length; i++) {
        sum += weight[i] * parseInt(val[i])
      }
      sum %= 10
      if ((10 - sum) % 10 !== controlNumber)
        return validationMessages.WrongPesel()
    },
  },
  postalCode: { required: validationMessages.Required() },
}

export function Individual({
  fieldMode,
  control,
  voivodeships,
  countries,
  taxOffices,
}: Props) {
  return (
    <>
      <TextBoxField
        name="street"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.street}
        label={labels.Street().toLowerCase()}
      />
      <TextBoxField
        name="buildingNumber"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.buildingNumber}
        label={labels.HomeNumber().toLowerCase()}
      />
      <TextBoxField
        name="premisesNumber"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.premisesNumber}
        label={labels.ApartmentNumber().toLowerCase()}
      />
      <TextBoxField
        name="city"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.city}
        label={labels.City().toLowerCase()}
      />
      <TextBoxField
        name="postalCode"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.postalCode}
        label={labels.PostalCode().toLowerCase()}
        inputProps={{ inputMode: 'text', autoComplete:"off" }}
        mask="__-___"
        maskReplacement={{ _: /\d/ }}
      />
      <SelectField
        label={labels.Voivodeship().toLowerCase()}
        isReadOnly={fieldMode == FieldModes.Read}
        name="voivodeship"
        options={voivodeships}
        control={control}
        rules={validationRules.voivodeshipId}
        formatOptionLabel={(e: any) => e.value}
      />
      <TextBoxField
        name="commune"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.commune}
        label={labels.Commune().toLowerCase()}
      />
      <SelectField
        label={labels.Country().toLowerCase()}
        name="country"
        isReadOnly={fieldMode == FieldModes.Read}
        isDisabled={true}
        options={countries}
        defaultValue={defaultCountry}
        control={control}
        formatOptionLabel={(e: any) => e.value}
      />
      <div className={styles.taxOffice}>
        <SelectField
          label={labels.TaxOffice().toLowerCase()}
          name="taxOffice"
          isReadOnly={fieldMode == FieldModes.Read}
          options={taxOffices}
          control={control}
          rules={validationRules.taxOfficeId}
          formatOptionLabel={(e: any) => e.value}
        />
      </div>

      <TextBoxField
        name="postOffice"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.postOffice}
        label={labels.PostOffice().toLowerCase()}
      />
      <TextBoxField
        name="pesel"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.pesel}
        label={labels.Pesel().toLowerCase()}
        inputProps={{ inputMode: 'text', autoComplete:"off" }}
        mask="___________"
        maskReplacement={{ _: /\d/ }}
      />
    </>
  )
}
