import React, { memo } from 'react';
import { useEffect } from 'react';

interface IntervalProps {
tick?: number,
onTick: ()=>void;

}

const Interval: React.FC<IntervalProps> = ({tick = 2000, onTick}) => {
    useEffect(() => {
        const interval = setInterval(() => {
            onTick();
          }, 2000)
      
          return () => {
            clearInterval(interval)
          }
    },[]);

    return (
        <>
        </>
    );
}

export default memo(Interval);