import React, { memo, useState } from 'react'
import { Portal } from '../Portal/Portal'
import styles from './DevelopVersionInfo.module.scss'
import GitInfo from 'react-git-info/macro'
import clsx from 'clsx'
import { useEffect } from 'react'
import Button from '../Button/Button'
import Interval from './Interval'
import AnimationBlock from '../AnimationBlock/AnimationBlock'

interface DevelopVersionInfoProps {
  children: React.ReactElement
}

const DevelopVersionInfo: React.FC<DevelopVersionInfoProps> = ({
  children,
}) => {
  const gitInfo = GitInfo()
  const [visible, setVisible] = useState(false)
  const [clickCount, setClickCount] = useState(0)
  const [startCounting, setStartCounting] = useState(false)

  const _onClick = () => {
    const count = clickCount + 1
    if (clickCount == 0) {
      setStartCounting(true)
    }
    if (count == 4) {
      setVisible((prevState) => !prevState)
      setClickCount(0)
    }

    setClickCount(count)
  }

  const _onHide = () => {
    setVisible(false)
  }

  const _onTick = () => {
    setClickCount(0)
    setStartCounting(false)
  }

  return (
    <>
      {React.cloneElement(children, { onMouseDown: _onClick })}
      {startCounting && <Interval onTick={_onTick} />}
      {visible && (
        <Portal>
          <AnimationBlock>
            <div className={clsx(styles.container)}>
              <h1>Build info:</h1>
              <table className={clsx(styles.table)}>
                <tr>
                  <td>Branch name:</td>
                  <td>{gitInfo.branch}</td>
                </tr>
                <tr>
                  <td>Commit:</td>
                  <td>{gitInfo.commit.hash}</td>
                </tr>
                <tr>
                  <td>Commit message:</td>
                  <td>{gitInfo.commit.message}</td>
                </tr>
                <tr>
                  <td>Commit date:</td>
                  <td>{gitInfo.commit.date}</td>
                </tr>
                {gitInfo.tags.length > 0 && (
                  <tr>
                    <td>Tag:</td> <td>{gitInfo.tags}</td>
                  </tr>
                )}
              </table>
              <Button
                size={'sm'}
                onClick={_onHide}
              >
                Close
              </Button>
            </div>
          </AnimationBlock>
        </Portal>
      )}
    </>
  )
}

export default memo(DevelopVersionInfo)
