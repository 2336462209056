import React, { DetailedHTMLProps, MouseEventHandler, ReactElement, ReactHTMLElement, memo, useCallback } from "react";
import clsx from "clsx";

import styles from "./Button.module.scss";
import { SvgIcon } from "../SvgIcon/SvgIcon";
import { IconTypes } from "../SvgIcon/Icons/icons";

export type ButtonProps  = {
	size?: "sm" | "md";
	className?: string;
	children?: ReactElement | string | ReactElement[];
	onClick?: MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	buttonType?: "primary" | "secondary";
	icon?: IconTypes;
	animation?: boolean;
	refs?: React.Ref<HTMLButtonElement>;
} & DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const Button: React.FC<ButtonProps> = ({ size = "md", color, buttonType = "primary", children, disabled, onClick, className, icon, animation, refs, ...props }) => {
	const onClickHandle = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			if (onClick && !disabled) onClick(e);
		},
		[onClick, disabled],
	);

	return (
		<button {...props} ref={refs} disabled={disabled} onClick={onClickHandle} className={clsx(styles.button, icon && styles.withIcon,  styles[`${size}`], styles[`${buttonType}`], className, disabled && styles.disabled)}>
			{icon ? (
				<>
					<SvgIcon className={styles.icon} icon={icon} animation={animation}/>
					{children && <span className={clsx(styles.label, animation && styles.labelAnimation)}>{children}</span>}
				</>
			) : (
				children
			)}
		</button>
	);
};

export default memo(Button);
