import React, { memo } from "react";
import Checkbox, { CheckboxProps } from "../ui/Checkbox/Checkbox";
import { FieldValues, useController } from "react-hook-form";
import ValidationLabel from "../ui/ValidationLabel/ValidationLabel";
import { clsx } from 'clsx';
import { formatFieldLabel } from "../../common/helpers/formHelper";

function CheckboxField({ name, control, rules, ...props }: FieldValues & CheckboxProps) {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		disabled: props.disabled,
	});

	return (
		<div className={clsx(props.className)}>
			<Checkbox 
				id={name} 
				value={field?.value} 
				refs={field?.ref} 
				onChange={field?.onChange} 
				hasError={!!fieldState.error} 
				{...props} 
				className=""
				label={formatFieldLabel(props,rules)}

			/>
			<ValidationLabel error={fieldState.error} />
		</div>
	);
}

export default memo(CheckboxField);
