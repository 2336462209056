/* eslint-disable import/no-anonymous-default-export*/
// eslint-disable import/no-anonymous-default-export
// File was autogenerated by tools/generateLabels.js script
import ResourceService from '../../services/resourceService';
const _ = (path:string) => ():string => ResourceService.get(path);

export default {
        ForgotPasswordMessage: _('templates.ForgotPasswordMessage'),
        SMSPhoneNumberValidationMessage: _('templates.SMSPhoneNumberValidationMessage'),
};
