import React, { memo, useRef, useState } from 'react'
import styles from './TextCollapseButton.module.scss'
import clsx from 'clsx'
import Button from '../Button/Button'
import languageService from '../../../common/services/languageService'
import { useLayoutEffect, useEffect } from 'react'
import { clientWidth } from '../../../common/hooks/useBrowserDeviceDetector'
const { labels } = languageService

interface TextCollapseButtonProps {
  text: string
  defaultCollapsed?: boolean
  showMoreLabel?: string
  hideMoreLabel?: string
}

const TextCollapseButton: React.FC<TextCollapseButtonProps> = ({
  text,
  defaultCollapsed = true,
  showMoreLabel = labels.ShowMore(),
  hideMoreLabel = labels.ShowLess(),
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed)
  const textRef = useRef<HTMLDivElement>(null)
  const showMoreBtnRef = useRef<HTMLButtonElement>(null)

  const _onClickShowMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    const _collapsed = !collapsed
    if (_collapsed)
      textRef.current?.style.setProperty(
        'margin-top',
        `-${
          textRef.current?.clientHeight + textRef.current.style.marginBottom
        }px`
      )
    if (!_collapsed) textRef.current?.style.removeProperty('margin-top')
    setCollapsed(_collapsed)
  }

  const _showMoreLabel = showMoreLabel ?? labels.ShowMore()
  const _hideMoreLabel = hideMoreLabel ?? labels.ShowLess()

  useLayoutEffect(() => {
    if (collapsed) {
      textRef.current?.style.setProperty('transition', 'none')
      textRef.current?.style.setProperty(
        'margin-top',
        `-${
          textRef.current?.clientHeight + textRef.current.style.marginBottom
        }px`
      )
      setTimeout(() => {
        textRef.current?.style.removeProperty('transition')
      }, 20)
    }
    if (!collapsed) {
      textRef.current?.style.removeProperty('margin-top')
    }
  }, [text, clientWidth.value])

  return (
    <div className={styles.container}>
      <div
        className={clsx(
          styles.textWrapper,
          collapsed ? styles.collapsedText : styles.notCollapsedText
        )}
      >
        <p
          ref={textRef}
          className={styles.text}
        >
          {text}
        </p>
      </div>
      <div className={styles.showMore}>
        <Button
          refs={showMoreBtnRef}
          className={
            collapsed ? styles.collapsedButton : styles.notCollapsedButton
          }
          icon="circleSimpleArrowLeft"
          onClick={_onClickShowMore}
          animation
        >
          {collapsed ? _showMoreLabel : _hideMoreLabel}
        </Button>
      </div>
    </div>
  )
}

export default memo(TextCollapseButton)
