import React, { memo } from "react";
import Row from "../../../components/ui/FormLayout/Row";
import Col from "../../../components/ui/FormLayout/Col";
import AnimationBlock from "../../../components/ui/AnimationBlock/AnimationBlock";
import TextBoxField from "../../../components/form/TextBoxField";
import { ProfileFormModes, ProfileValues } from "../Profile";
import TextBoxWithQrScanner from "../../../components/ui/TextBoxWithQrScanner/TextBoxWithQrScanner";
import RadioButtonList, {
  RadioButtonListOption,
} from "../../../components/form/RadioButtonList";
import { LegalEntity } from "../LegalEntity/LegalEntity";
import { Individual } from "../Individual/Individual";
import CheckboxField from "../../../components/form/CheckboxField";
import { RegulationsTypes } from "../../../common/consts/RegulationsTypes";
import Link from "../../../components/ui/Link/Link";
import ValidationLabel from "../../../components/ui/ValidationLabel/ValidationLabel";
import Button from "../../../components/ui/Button/Button";
import { FieldModes } from "../../../common/consts/FieldModes";
import {
  Control,
  FieldError,
  FieldErrors,
  RegisterOptions,
} from "react-hook-form";
import { registrationSteps } from "../../CreateProfile/CreateProfile";
import { FieldsRegisterOptions } from "../../../components/form/FieldType";
import PasswordInput from "../../../components/ui/PasswordInput/PasswordInput";
import styles from ".././Profile.module.scss";
import clsx from 'clsx'
import { ClerkTypes } from "../../../common/consts/ClerkTypes";
import { getRegulationLink } from "../../../common/services/clerkService";
import ReCAPTCHA from "react-google-recaptcha";

import langs from "../../../common/services/languageService";
import ReCaptchaField from "../../../components/form/ReCaptchaField";
const { labels } = langs;

interface ClerkRegistrationStepProps {
  clerkTypeId: number;
  clerkTypeOptions: RadioButtonListOption[];
  companyEditDisabled?: boolean;
  control: Control<any>;
  countries: [];
  fieldMode: FieldModes;
  formMode: ProfileFormModes;
  getSalePointData: (e: any) => void;
  onSetEdit?: () => void;
  onQrScanned: (controlName: string, value: string) => void;
  serverErrorMessage?: string;
  submitButtonText: string;
  taxOffices: [];
  validationRules: FieldsRegisterOptions<ProfileValues, RegisterOptions>;
  voivodeships: [];
  recaptchaRef?: any;
  onReCaptchaChange?: (key: string | null) => void;
  errors?: FieldErrors<ProfileValues>;
}

const ClerkRegistrationStep: React.FC<ClerkRegistrationStepProps> = ({
  clerkTypeId,
  clerkTypeOptions,
  companyEditDisabled,
  control,
  countries,
  fieldMode,
  formMode,
  getSalePointData,
  onSetEdit,
  onQrScanned,
  serverErrorMessage,
  submitButtonText,
  taxOffices,
  validationRules,
  voivodeships,
  recaptchaRef,
  onReCaptchaChange,
  errors,
}) => {
  return (
    <>
      <Row className={clsx(fieldMode == FieldModes.Read && styles.pcReadOnly)}>
        <Col>
          <TextBoxField
            name="email"
            control={control}
            rules={validationRules.email}
            isReadOnly
            label={labels.EmailShort().toLowerCase()}
            inputProps={{ autoComplete: "email" }}
          />
        </Col>
      </Row>
      <Row className={clsx(fieldMode == FieldModes.Read && styles.pcReadOnly)}>
        <Col>
          <AnimationBlock>
            <TextBoxField
              name="firstName"
              isReadOnly={fieldMode == FieldModes.Read}
              control={control}
              rules={validationRules.firstName}
              label={labels.FirstName().toLowerCase()}
              inputProps={{ autoComplete: "given-name" }}
            />
            <TextBoxField
              name="lastName"
              isReadOnly={fieldMode == FieldModes.Read}
              control={control}
              rules={validationRules.lastName}
              label={labels.LastName().toLowerCase()}
              inputProps={{ autoComplete: "family-name" }}
            />
            {formMode == ProfileFormModes.register && (
              <>
                <PasswordInput
                  name="password"
                  passwordType="new-password"
                  control={control}
                  rules={validationRules.password}
                  label={labels.Password().toLowerCase()}
                />
                <PasswordInput
                  name="confirmPassword"
                  passwordType="new-password"
                  control={control}
                  rules={validationRules.confirmPassword}
                  label={labels.ConfirmPassword().toLowerCase()}
                />
              </>
            )}
            <TextBoxField
              name="phoneNumber"
              isReadOnly={fieldMode == FieldModes.Read}
              control={control}
              rules={validationRules.phoneNumber}
              label={labels.PhoneNumber().toLowerCase()}
              inputProps={{ inputMode: "tel", autoComplete: "off" }}
              mask="+48 ___ ___ ___"
              maskReplacement={{ _: /\d/ }}
            />
            {formMode == ProfileFormModes.register && (
              <CheckboxField
                control={control}
                name="adultConfirmation"
                rules={validationRules.adultConfirmation}
                id="adultConfirmation"
                label={labels.ConfirmAdult()}
              />
            )}
            <TextBoxField
              name="rtNumber"
              isReadOnly={formMode == ProfileFormModes.profile}
              label={labels.PointOfSaleCode().toLowerCase()}
              control={control}
              rules={validationRules.rtNumber}
            />
            <TextBoxWithQrScanner
              name="qrCode"
              isReadOnly={formMode == ProfileFormModes.profile}
              label={labels.QRSalePoint()}
              control={control}
              validationRule={validationRules.qrCode}
              onQrScanned={onQrScanned}
            />
            {formMode == ProfileFormModes.register && (
              <div className={styles.accountType}>
                <p>{labels.ChooseAccountType()}*</p>
                <RadioButtonList
                  control={control}
                  rules={validationRules.clerkTypeId}
                  name="clerkTypeId"
                  options={clerkTypeOptions}
                />
              </div>
            )}
          </AnimationBlock>
        </Col>
        <Col>
          <AnimationBlock animationDelay="0.2s" animation="fade-in">
            {clerkTypeId == ClerkTypes.legalEntity && (
              <LegalEntity
                fieldMode={companyEditDisabled ? FieldModes.Read : fieldMode}
                formMode={formMode}
                getSalePointData={getSalePointData}
                control={control}
              />
            )}
            {clerkTypeId == ClerkTypes.individual && (
              <Individual
                fieldMode={fieldMode}
                formMode={formMode}
                control={control}
                countries={countries}
                voivodeships={voivodeships}
                taxOffices={taxOffices}
              />
            )}
            {formMode == ProfileFormModes.register &&
              (clerkTypeId == ClerkTypes.individual ||
                clerkTypeId == ClerkTypes.legalEntity) && (
                <CheckboxField
                  control={control}
                  name="confirmRegulations"
                  rules={validationRules.confirmRegulations}
                  id="confirmRegulations"
                  label={
                  <>
                    <span style={{marginRight: "3px"}}>{labels.Accept()}</span>
                    {formMode == ProfileFormModes.register && (
                      <Link
                        download
                        href={getRegulationLink({
                          type: RegulationsTypes.platform,
                          offerId: null,
                        })}
                      >
                        {labels.Regulation().toLowerCase()}
                      </Link>
                    )}
                  </>}
                />
              )}
          </AnimationBlock>
        </Col>
      </Row>
      <Row>
        <Col>
          {!!serverErrorMessage && (
            <ValidationLabel
              centered
              error={{ message: serverErrorMessage } as FieldError}
            />
          )}
          {formMode == ProfileFormModes.register && (
            <ReCaptchaField name="captchaKey" control={control} className={styles.recaptcha} />
          )}
          <AnimationBlock animationDelay="0.4s">
            <div className={styles.buttons}>
              <Button
                buttonType="primary"
                size="md"
                type={fieldMode == FieldModes.Read ? "button" : "submit"}
                onClick={(e) => {
                  if (onSetEdit && fieldMode == FieldModes.Read) {
                    e.preventDefault();
                    onSetEdit();
                  }
                }}
              >
                {submitButtonText.toLowerCase()}
              </Button>
            </div>
          </AnimationBlock>
        </Col>
      </Row>
    </>
  );
};

export default memo(ClerkRegistrationStep);
