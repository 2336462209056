import React, { memo } from 'react'
import styles from './ProgramsForYou.module.scss'
import Content from '../../components/ui/Content/Content'
import languageService from '../../common/services/languageService'
import { SvgIcon } from '../../components/ui/SvgIcon/SvgIcon'
import Card from '../../components/ui/Card/Card'
import Select from '../../components/ui/Select/Select'
import { ClerkRewardProgram } from '../../common/models/ClerkRewardProgram'
import LabelWithValue from '../../components/ui/LabelWithValue/LabelWithValue'
import AnimationBlock from '../../components/ui/AnimationBlock/AnimationBlock'
import { isMobile } from '../../common/hooks/useBrowserDeviceDetector'
import clsx from 'clsx'
const { labels, validationMessages } = languageService

interface ProgramsForYouUIProps {
  isLoaded?: boolean
  activeRewardProgram: ClerkRewardProgram
  selectedArchivedProgram: ClerkRewardProgram
  archivedPrograms: ClerkRewardProgram[] | undefined
  onArchiveProgramChange: (data: any) => void
  onGoBack: () => void
}

const ProgramsForYouUI: React.FC<ProgramsForYouUIProps> = ({
  activeRewardProgram,
  isLoaded,
  selectedArchivedProgram,
  archivedPrograms,
  onArchiveProgramChange,
  onGoBack,
}) => {
  return (
    <>
      {isLoaded && (
        <Content
          title={labels.ProgramsForYou()}
          icon={<SvgIcon icon={'star'} />}
          onGoBack={onGoBack}
        >
          <div className={styles.container}>
            <div className={styles.actualProgram}>
              <AnimationBlock animationDuration="0.5s">
                <h2 className="mt-0 mb-2">{labels.ActualProgram()}</h2>

                {activeRewardProgram && (
                  <div className="mb-2">
                    <LabelWithValue
                      valueAccentColor
                      label={labels.Counter()}
                      value={activeRewardProgram?.collectedPoints}
                    />
                    <LabelWithValue
                      valueAccentColor
                      label={labels.Reward()}
                      value={activeRewardProgram?.actualReward}
                    />
                  </div>
                )}
              </AnimationBlock>

              <AnimationBlock
                animationDuration="0.5s"
                animationDelay="0.1s"
              >
                {activeRewardProgram ? (
                <Card
                  header={activeRewardProgram?.name}
                  image={activeRewardProgram?.picture}
                  showMoreLabel={labels.ShowProgramRegulations().toLowerCase()}
                  hideMoreLabel={labels.HideProgramRegulations().toLowerCase()}
                  description={activeRewardProgram?.description}
                />) :
                (
                  <Card headline={labels.NoActiveProgram()}/>
                  // <div className={styles.noActiveProgram}>{labels.NoActiveProgram()}</div>
                )
              }
                {isMobile() && <hr />}
              </AnimationBlock>
            </div>
            <AnimationBlock
              animationDuration="0.5s"
              animationDelay="0.2s"
            >
              <div className={clsx(styles.archivePrograms)}>
                <h2 className="mt-0">{labels.ProgramArchive()}</h2>
                <Select
                  value={selectedArchivedProgram}
                  label={labels.SelectProgram()}
                  onChange={onArchiveProgramChange}
                  options={archivedPrograms}
                  getOptionLabel={(x: any) => `${x?.name}`}
                  getOptionValue={(x: any) => x?.id}
                  minMenuHeight={300}
                  menuPlacement="auto"
                />
                {selectedArchivedProgram && (
                  <AnimationBlock animationDuration="0.5s">
                    <Card
                      description={selectedArchivedProgram?.description}
                      showMoreLabel={labels
                        .ShowProgramRegulations()
                        .toLowerCase()}
                      hideMoreLabel={labels
                        .HideProgramRegulations()
                        .toLowerCase()}
                      headline={
                        <div className="mb-2">
                          <LabelWithValue
                            valueAccentColor
                            label={labels.Counter()}
                            value={selectedArchivedProgram?.collectedPoints}
                          />
                          <LabelWithValue
                            valueAccentColor
                            label={labels.Reward()}
                            value={selectedArchivedProgram?.actualReward}
                          />
                        </div>
                      }
                    />
                  </AnimationBlock>
                )}
              </div>
            </AnimationBlock>
          </div>
        </Content>
      )}
    </>
  )
}

export default memo(ProgramsForYouUI)
