import React, { memo } from "react";
import { Offer } from "../../../common/models/Offer";
import { OfferCard } from "../../../components/ui/OfferCard/OfferCard";
import AnimationBlock from "../../../components/ui/AnimationBlock/AnimationBlock";
import styles from "../OfferSale.module.scss";
import langs from "../../../common/services/languageService";
const { labels } = langs;

interface SelectOfferUIProps {
  offers: Offer[];
  onSelectOffer: (selectedOffer: Offer) => void;
}

const SelectOfferUI: React.FC<SelectOfferUIProps> = ({ offers, onSelectOffer }) => {
  return (
    <>
      <span className={styles.selecOfferText}>{labels.ChooseOffer()}:</span>
      <span className={styles.selecOfferInfo}>{labels.SelectOfferInfo()}</span>
      {offers.map((offer, index) => (
        <AnimationBlock key={offer.offerId} animationDelay={`${index * 0.1}s`}>
          <OfferCard offer={offer} showDescription onClick={onSelectOffer} />
        </AnimationBlock>
      ))}
    </>
  );
};

export default memo(SelectOfferUI);
