import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { parseJwt } from "./helpers/tokenHelper";
import { Token } from "./models/Token";
import { ClerkLoginResult } from "./models/ClerkLoginResult";

export interface CommonState {
  loadingIndicatorCounter: number;
  token: Token | null;
  permissions: string[] | null;
}

const initialState: CommonState = {
  loadingIndicatorCounter: 0,
  token: parseJwt(localStorage.getItem("token")),
  permissions: localStorage.getItem("permissions")?.split(",") || null,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    startAsyncOperation: (state) => {
      ++state.loadingIndicatorCounter;
    },
    finishAsyncOperation: (state) => {
      if (state.loadingIndicatorCounter == 0) return;
      --state.loadingIndicatorCounter;
    },
    setLoginData: (state, action: PayloadAction<ClerkLoginResult>) => {
      var parsedToken = parseJwt(action.payload.accessToken);
      var refreshExpireTime = new Date(action.payload.refreshTokenExpire).getTime();
      state.token = { ...parsedToken, expire: refreshExpireTime } as Token;
      state.permissions = action.payload.permissions;

      localStorage.setItem("userId", action.payload.userId)!;
      localStorage.setItem("email", parsedToken!.email)!;
      localStorage.setItem("token", action.payload.accessToken);
      localStorage.setItem("accessTokenExpire", action.payload.accessTokenExpire.toString());
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      localStorage.setItem("refreshTokenExpire", action.payload.refreshTokenExpire.toString());
      localStorage.setItem("permissions", action.payload.permissions.toString());
    },
  },
});

export const { startAsyncOperation, finishAsyncOperation, setLoginData } = commonSlice.actions;

export const showLoadingIndicatorSelector = (state: RootState) => state.common.loadingIndicatorCounter > 0;

export const clerkIdSelector = (state: RootState) => state.common.token?.clerkId;

export const permissionsSelector = (state: RootState) => state.common.permissions;

export const clerkEmailSelector = (state: RootState) => state.common.token?.email;

export default commonSlice.reducer;
