import { useEffect, useState } from "react";
import { Greeting } from "./Greeting/Greeting";
import { Login } from "./Login/Login";
import { useAppDispatch } from "../../app/hooks";
import langs from "../../common/services/languageService";

const { labels } = langs;

export function LoginPage() {
  const dispatch = useAppDispatch();
  const [showLoginPage, setShowLoginPage] = useState(false);

  return (
    <>
      {!showLoginPage && (
        <Greeting onLoginClick={() => setShowLoginPage(true)} />
      )}
      {showLoginPage && (
        <Login onLoginClick={() => console.log("onLoginClick")} />
      )}
    </>
  );
}
