import { Token } from "../models/Token";

export function parseJwt(token: string | null): Token | null {
  if (token == null)
    return null;

  var parsedToken = parseToken(token);

  return {
    clerkId: parsedToken.ClerkId,
    expire: parsedToken.exp * 1000,
    email: parsedToken.Email,
    token: token
  }
}

export function getEmailFromToken(token: string): string {
  var parsedToken = parseToken(token);

  return parsedToken.Email;
}

function parseToken(token: string): any {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  var parsedToken = JSON.parse(jsonPayload);

  return parsedToken;
}
