import { phoneNumberRegex } from "../../common/helpers/validationHelper";
import { finishAsyncOperation, showLoadingIndicatorSelector, startAsyncOperation } from "../../common/commonSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { editClerk, getClerkData, getPointOfSale, getUserDictionary } from "../../common/services/clerkService";
import langs from "../../common/services/languageService";
import { formatPhoneNumber, formatPhoneNumberToInputFormat } from "../../common/helpers/formHelper";
import { parseInt } from "lodash";
import { selectCountries, selectTaxOffices, selectVoivodeships, setDictionary } from "../../common/dictionariesSlice";
import UserDictionaryTypes from "../../common/consts/UserDictionaryTypes";
import { ClerkTypes } from "../../common/consts/ClerkTypes";
import { RadioButtonListOption } from "../../components/form/RadioButtonList";
import Profile, { ProfileFormModes, ProfileValues } from "../Profile/Profile";
import { FieldModes } from "../../common/consts/FieldModes";

const { labels, validationMessages } = langs;

export enum registrationSteps {
  emailVerification,
  clerkRegistration,
  accountExists,
  clerkRegistrationSuccess,
}

const clerkTypeOptions = [
  {
    id: "individual",
    value: ClerkTypes.individual,
    label: labels.PhysicalPerson(),
    checked: false,
  },
  {
    id: "legalEntity",
    value: ClerkTypes.legalEntity,
    label: labels.LegalPerson(),
    checked: false,
  },
] as RadioButtonListOption[];

export function EditProfile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [fieldMode, setFieldMode] = useState(FieldModes.Read);
  const [companyEditDisabled, setCompanyEditDisabled] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState(labels.EditProfile().toLowerCase());
  const countries = useAppSelector(selectCountries);
  const voivodeships = useAppSelector(selectVoivodeships);
  const taxOffices = useAppSelector(selectTaxOffices);
  const {
    control,
    formState: { errors, isValid, isSubmitted },
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
  } = useForm<ProfileValues>({
    mode: "onChange",
    criteriaMode: "all",
  });

  useEffect(() => {
    init();
  }, []);

  const clerkTypeId = watch("clerkTypeId");

  const validationRules = {
    firstName: {
      required: validationMessages.Required(),
      minLength: {
        value: 2,
        message: validationMessages.MinimumLength().replace("{0}", "2"),
      },
      maxLength: {
        value: 100,
        message: validationMessages.MaximumLength().replace("{0}", "100"),
      },
    },
    lastName: {
      required: validationMessages.Required(),
      minLength: {
        value: 2,
        message: validationMessages.MinimumLength().replace("{0}", "2"),
      },
      maxLength: {
        value: 100,
        message: validationMessages.MaximumLength().replace("{0}", "100"),
      },
    },
    phoneNumber: {
      required: validationMessages.Required(),
      pattern: {
        value: phoneNumberRegex,
        message: validationMessages.PhoneNumber(),
      },
    },
    adultConfirmation: { required: validationMessages.Required() },
    confirmRegulations: { required: validationMessages.Required() },
    clerkTypeId: { required: validationMessages.Required() },
  };

  const getSalePointData = (event: any) => {
    event.preventDefault();
    const rtNumber = getValues("rtNumber");

    if (!rtNumber) return;

    dispatch(startAsyncOperation());

    getPointOfSale(rtNumber)
      .then((resp) => {
        setValue("nip", resp.data.nip, { shouldValidate: true });
        setValue("companyName", resp.data.companyName, {
          shouldValidate: true,
        });
        dispatch(finishAsyncOperation());
      })
      .catch(() => {
        dispatch(finishAsyncOperation());
      });
  };

  const onQrScanned = (controlName: string, value: string) => {
    setValue(controlName as any, value, { shouldValidate: true });
  };

  const onGoBack = () => {
    navigate("/");
  };

  const onSetEdit = () => {
    setFieldMode(FieldModes.Edit);
    setSubmitButtonText(labels.Save());
  };

  const blockEditingLegalEntity = (clerkTypeId: number) => {
    if (clerkTypeId == ClerkTypes.legalEntity) setCompanyEditDisabled(true);
  };

  const onSubmit: SubmitHandler<ProfileValues> = (data) => {
    setFieldMode(FieldModes.Read);
    setSubmitButtonText(labels.EditProfile());

    var payload: ProfileValues = {
      ...data,
      countryId: "10001",
      voivodeshipId: data.voivodeship?.id,
      taxOfficeId: data.taxOffice?.id,
      phoneNumber: formatPhoneNumber(data.phoneNumber),
      clerkTypeId: parseInt("" + data.clerkTypeId),
    };

    dispatch(startAsyncOperation());
    editClerk(payload)
      .then((resp) => {
        dispatch(finishAsyncOperation());
        blockEditingLegalEntity(payload.clerkTypeId);
      })
      .catch((err) => {
        dispatch(finishAsyncOperation());
      });
  };

  function init() {
    // Clerk Data
    dispatch(startAsyncOperation());
    getClerkData()
      .then((resp) => {
        reset(resp.data, {});
        setValue("phoneNumber", formatPhoneNumberToInputFormat(resp.data.phoneNumber), { shouldValidate: true });

        blockEditingLegalEntity(resp.data.clerkTypeId);

        // Countries Dictionary
        dispatch(startAsyncOperation());
        getUserDictionary(UserDictionaryTypes.countries).then((resp) => {
          var optionValue: any = resp.data.find((x) => (x as any).id == getValues("countryId"));
          setValue("country", optionValue as any);
          dispatch(
            setDictionary({
              type: UserDictionaryTypes.countries,
              items: resp.data,
            })
          );
          dispatch(finishAsyncOperation());
        });

        // Tax offices Dictionary
        dispatch(startAsyncOperation());
        getUserDictionary(UserDictionaryTypes.taxOffices).then((resp) => {
          var optionValue: any = resp.data.find((x) => (x as any).id == getValues("taxOfficeId"));
          setValue("taxOffice", optionValue as any);
          dispatch(
            setDictionary({
              type: UserDictionaryTypes.taxOffices,
              items: resp.data,
            })
          );
          dispatch(finishAsyncOperation());
        });

        // Voivodeships Dictionary
        dispatch(startAsyncOperation());
        getUserDictionary(UserDictionaryTypes.voivodeships).then((resp) => {
          var optionValue: any = resp.data.find((x) => (x as any).id == getValues("voivodeshipId"));
          setValue("voivodeship", optionValue as any);
          dispatch(
            setDictionary({
              type: UserDictionaryTypes.voivodeships,
              items: resp.data,
            })
          );
          dispatch(finishAsyncOperation());
        });
        dispatch(finishAsyncOperation());
      })
      .catch(() => {
        dispatch(finishAsyncOperation());
      });
  }
  const isLoaded = !useAppSelector(showLoadingIndicatorSelector);
  return (
    <>
    {isLoaded && (
      <Profile
        clerkTypeId={clerkTypeId}
        clerkTypeOptions={clerkTypeOptions}
        companyEditDisabled={companyEditDisabled}
        control={control}
        countries={countries}
        errors={errors}
        fieldMode={fieldMode}
        formMode={ProfileFormModes.profile}
        formStep={registrationSteps.clerkRegistration}
        getSalePointData={getSalePointData}
        isValid={isValid}
        onSetEdit={onSetEdit}
        onGoBack={onGoBack}
        onQrScanned={onQrScanned}
        onSubmit={handleSubmit(onSubmit)}
        serverErrorMessage=""
        submitButtonText={submitButtonText}
        taxOffices={taxOffices}
        title={labels.Profile()}
        validationRules={validationRules}
        voivodeships={voivodeships}
      />
      )}
    </>
  );
}
