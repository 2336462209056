import React, { memo, useRef, useState } from 'react'
import styles from './Card.module.scss'
import Image from '../Image/Image'
import { SvgIcon } from '../SvgIcon/SvgIcon'
import Button from '../Button/Button'
import languageService from '../../../common/services/languageService'
import clsx from 'clsx'
import TextCollapseButton from '../TextCollapseButton/TextCollapseButton'
const { labels } = languageService

interface CardProps {
  headline?: string | React.ReactElement
  header?: string | React.ReactElement
  /**
   * png image in base64
   */
  image?: string
  description?: string
  showMoreLabel?: string
  hideMoreLabel?: string
  onClick?: () => void
}

const Card: React.FC<CardProps> = ({
  headline,
  header,
  image,
  description,
  showMoreLabel,
  hideMoreLabel,
  onClick,
}) => {
  const imageSrc = image ? `data:image/png;base64, ${image}` : ''
  const isClickable = onClick != undefined;

  return (
    <div className={clsx(styles.container, isClickable && styles.clickable)} onClick={onClick}>
      {headline && <span className={styles.headline}>{headline}</span>}
      {header && <h2 className={styles.header}>{header}</h2>}
      {imageSrc && (
        <Image
          src={imageSrc}
          className={styles.image}
        />
      )}
      {description && (
        <TextCollapseButton
          text={description}
          showMoreLabel={showMoreLabel}
          hideMoreLabel={hideMoreLabel}
        />
      )}
    </div>
  )
}

export default memo(Card)
