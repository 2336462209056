import React, { ReactElement, memo } from "react";
import styles from "./FormLayout.module.scss";
import { clsx } from 'clsx';

export type ColProps = {
    className?: string;
	children?: (boolean | ReactElement | string)[] | ReactElement | boolean | string;
	renderIfEmpty?: boolean;
};

const Col: React.FC<ColProps> = ({ children, renderIfEmpty, className }) => {
	if (!renderIfEmpty && !children) return <></>;

	return <div className={clsx(styles.col, className)}>{children}</div>;
};

export default memo(Col);
