import {
  FocusEvent,
  InputHTMLAttributes,
  MouseEvent,
  useCallback,
  useRef,
  useState,
} from 'react'
import TextBoxField from '../../form/TextBoxField'
import { Control } from 'react-hook-form'
import QrScanner from '../QrScanner/QrScanner'
import { SvgIcon } from '../SvgIcon/SvgIcon'
import styles from './TextBoxWithQrScanner.module.scss'
import clsx from 'clsx'
import {
  BrowserDeviceTypes,
  browserDeviceType,
} from '../../../common/hooks/useBrowserDeviceDetector'

type Props = {
  control: Control<any>
  validationRule?: any
  label: string
  name: string
  onQrScanned: (
    controlName: string,
    value: string,
    submitAfterScan?: boolean
  ) => void
  isReadOnly?: boolean
  showScannerByDefault?: boolean
  submitAfterScan?: boolean
}

export default function TextBoxWithQrScanner({
  control,
  validationRule,
  label,
  name,
  onQrScanned,
  isReadOnly,
  showScannerByDefault = false,
  submitAfterScan = false,
}: Props) {
  const _containerRef = useRef<HTMLDivElement>(null)
  const _ref = useRef<HTMLInputElement>(null)
  const [inputMode, setInputMode] = useState('none')
  const [inputIcon, setInputIcon] = useState<'qr' | 'keyboard'>('qr')

  const [showScanner, setShowScanner] = useState(showScannerByDefault)
  const [scanningComplete, setScanningComplete] = useState(false)
  const [showQrContainer, setShowQrContainer] = useState(showScannerByDefault)
  const onTextBoxFocus = async (e: FocusEvent<HTMLInputElement>) => {
    if (!showScanner) {
      if (browserDeviceType.value == BrowserDeviceTypes.Mobile)
        setInputIcon('keyboard')
      setShowQrContainer(true)
      setTimeout(() => {
        setShowScanner(true)
      }, 200)
    }
    setTimeout(() => {
      _containerRef?.current?.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      })
    }, 400)
  }

  const onTextBoxBlur = (e: FocusEvent<HTMLInputElement>): void => {
    setInputIcon('qr')
    setInputMode('none')
    if (
      (showScannerByDefault && control._fields[name]?._f.value) ||
      !showScannerByDefault
    )
      setShowScanner(false)
  }

  const _onMouseDown = (
    e: React.MouseEvent<any>,
    state: { isFocused?: boolean }
  ) => {
    if (showScanner && state?.isFocused) {
      setInputMode('text')
      if (browserDeviceType.value == BrowserDeviceTypes.Mobile)
        setInputIcon('qr')
      _ref.current?.focus()
      setShowScanner(false)
    } else {
      if (browserDeviceType.value == BrowserDeviceTypes.Mobile)
        setInputIcon('keyboard')
      setInputMode('none')
      setShowScanner(true)
      setShowQrContainer(true)

      setTimeout(() => {}, 400)
    }
  }

  const _onQrScanned = useCallback(
    (data: string) => {
      setScanningComplete(true)
      onQrScanned(name, data, submitAfterScan)
      setTimeout(() => {
        setScanningComplete(false)
        setShowScanner(false)
      }, 1200)
    },
    [name, onQrScanned, submitAfterScan]
  )

  return (
    <div
      className={styles.container}
      ref={_containerRef}
    >
      <TextBoxField
        className={styles.textBoxField}
        onMouseDown={_onMouseDown}
        refs={_ref}
        name={name}
        control={control}
        rules={validationRule}
        label={label}
        onFocus={onTextBoxFocus}
        onBlur={onTextBoxBlur}
        isReadOnly={isReadOnly}
        inputProps={{ inputMode: inputMode as 'text' | 'none' }}
        additionalElement={
          <SvgIcon
            icon={inputIcon}
            className={styles.nonClickable}
          />
        }
      />
      <div
        className={clsx(
          styles.qrContainer,
          showQrContainer && styles.showQrContainer
        )}
      >
        {showScanner && (
          <QrScanner
            onScannedSuccesful={_onQrScanned}
            additionalElement={
              scanningComplete ? (
                <>
                  <div className={styles.overlay_dark} />
                  <SvgIcon
                    className={styles.mark}
                    icon="circleCheck"
                    animation
                  />
                </>
              ) : undefined
            }
          />
        )}
      </div>
    </div>
  )
}
