import React, { memo, useEffect, useState } from "react";
import OfferSaleUI from "./OfferSaleUI";
import { useNavigate } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { OfferSaleType } from "../../common/dataTypes/OfferSaleType";
import {
  cancelTransaction,
  confirmSale,
  getSignUpToPointOfSale,
  selectOffer,
  validateCustomer,
  getCustomerRegistrationStatus,
} from "../../common/services/clerkService";
import { formatPhoneNumber } from "../../common/helpers/formHelper";
import Connector from "../../common/services/signalrService";
import { DeviceRegistrationResult } from "../../common/models/DeviceRegistrationResult";
import { signal } from "@preact/signals-react";
import { useDispatch } from "react-redux";
import { finishAsyncOperation, startAsyncOperation } from "../../common/commonSlice";
import { ValidateCustomerResp } from "../../common/models/ValidateCustomerResp";

import langs from "../../common/services/languageService";
import { Offer } from "../../common/models/Offer";
const { validationMessages } = langs;

interface OfferSaleProps {}

export enum offerSaleSteps {
  qrSalePointVerification,
  phoneNumberVerification,
  waitForRegistration,
  selectOffer,
  scanDevice,
  saleConfirmation,
  saleSuccess,
  saleFailed,
  noOffer,
}

export const offerSaleErrors = signal({} as FieldErrors);

const OfferSale: React.FC<OfferSaleProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cancelRegistration, listenOnTransaction, events } = Connector();
  const [formStep, setFormStep] = useState<offerSaleSteps>(offerSaleSteps.qrSalePointVerification);
  const [additionalData, setAdditionalData] = useState<any>(null);
  const [isDeviceScanRequired, setDeviceScanRequired] = useState(false);
  const [offers, setOffers] = useState<Offer[]>([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm<OfferSaleType>({
    mode: "onChange",
    criteriaMode: "all",
  });

  useEffect(() => {
    offerSaleErrors.value = errors;
  }, [errors]);

  useEffect(() => {
    events(
      () => {
        if (isDeviceScanRequired) setFormStep(offerSaleSteps.scanDevice);
        else setFormStep(offerSaleSteps.saleConfirmation);
      },
      () => {
        setFormStep(offerSaleSteps.saleFailed);
      }
    );
  });

  const stepsWithoutBackBtn = [
    offerSaleSteps.waitForRegistration,
    offerSaleSteps.saleSuccess,
    offerSaleSteps.saleFailed,
  ];
  const shouldShowGoBackBtn = !stepsWithoutBackBtn.includes(formStep);

  const _onGoBack = shouldShowGoBackBtn
    ? () => {
        switch (formStep) {
          case offerSaleSteps.qrSalePointVerification:
          case offerSaleSteps.scanDevice:
          case offerSaleSteps.saleConfirmation:
          case offerSaleSteps.noOffer:
            navigate("/");
            break;
          case offerSaleSteps.phoneNumberVerification:
            setFormStep(offerSaleSteps.qrSalePointVerification);
            break;
          case offerSaleSteps.selectOffer:
            setFormStep(offerSaleSteps.phoneNumberVerification);
            break;
        }
      }
    : undefined;

  const _onQrScanned = async (controlName: string, value: string, submitAfterScan?: boolean) => {
    setValue(controlName as keyof OfferSaleType, value, {
      shouldValidate: true,
    });
    if (submitAfterScan)
      setTimeout(() => {
        handleSubmit(_onSubmit)();
      }, 1000);
  };

  const _onSubmit = async (value: OfferSaleType) => {
    const phoneNumber = formatPhoneNumber(value.phoneNumber ?? "");
    const clerkId = Number(localStorage.getItem("userId")!);
    let result = null as any;
    dispatch(startAsyncOperation());
    try {
      switch (formStep) {
        case offerSaleSteps.qrSalePointVerification:
          result = await getSignUpToPointOfSale({
            qrCode: value.qrCode,
            clerkId: clerkId,
          });
          if (result.data === false) {
            setError("qrCode", {
              type: "custom",
              message: validationMessages.SalePointDoesntExist(),
            });
            break;
          }
          setFormStep(offerSaleSteps.phoneNumberVerification);
          break;

        case offerSaleSteps.phoneNumberVerification:
        case offerSaleSteps.noOffer:
          result = await validateCustomer({
            phoneNumber: phoneNumber,
            clerkId: clerkId,
            qrCode: value.qrCode,
          });
          setOffers(result.data.avaliableOffers);
          setValue("transactionId", result.data.transactionId);
          setAdditionalData(result.data);

          const validationResult = result.data as ValidateCustomerResp;
          if (validationResult.avaliableOffers?.length == 0) setFormStep(offerSaleSteps.noOffer);
          else if (validationResult.isUserRegistered) setFormStep(offerSaleSteps.selectOffer);
          else setFormStep(offerSaleSteps.selectOffer);

          break;

        case offerSaleSteps.selectOffer:
          setDeviceScanRequired(value.offer.isDeviceScanRequired);
          result = await selectOffer({
            phoneNumber: phoneNumber,
            offerId: value.offer.offerId,
            transactionId: value.transactionId,
          });
          if (result.data.registrationNeeded) {
            setFormStep(offerSaleSteps.waitForRegistration);
            listenOnTransaction(value.transactionId);
          } else if (value.offer.isDeviceScanRequired) {
            setFormStep(offerSaleSteps.scanDevice);
          } else setFormStep(offerSaleSteps.saleConfirmation);

          break;

        case offerSaleSteps.waitForRegistration:
          result = await cancelTransaction({
            transactionId: value.transactionId,
            phoneNumber: phoneNumber,
            clerkId: clerkId,
          });
          cancelRegistration(value.transactionId);
          navigate("/");
          break;

        case offerSaleSteps.scanDevice:
          result = await confirmSale({
            transactionId: value.transactionId,
            phoneNumber: phoneNumber,
            deviceNumber: value.deviceQRCode,
          });
          const scanDeviceResultData = result.data as DeviceRegistrationResult;
          if (scanDeviceResultData.isRegistrationSuccessfull) setFormStep(offerSaleSteps.saleSuccess);
          else {
            if (!scanDeviceResultData.isDeviceCodeAvaliable)
              setError("deviceQRCode", {
                type: "custom",
                message: validationMessages.WrongDeviceNumber(),
              });
            if (!scanDeviceResultData.isOfferAvaliable)
              setError("root.serverError", {
                type: "serverError",
                message: validationMessages.OfferNotAvailableMessage(),
              });
          }
          break;

        case offerSaleSteps.saleConfirmation:
          result = await confirmSale({
            transactionId: value.transactionId,
            phoneNumber: phoneNumber,
          });
          const saleConfirmationResultData = result.data as DeviceRegistrationResult;
          if (saleConfirmationResultData.isRegistrationSuccessfull) setFormStep(offerSaleSteps.saleSuccess);
          else if (!saleConfirmationResultData.isOfferAvaliable) {
            setError("root.serverError", {
              type: "serverError",
              message: validationMessages.OfferNotAvailableMessage(),
            });
            setFormStep(offerSaleSteps.saleFailed);
          }
          break;

        case offerSaleSteps.saleSuccess:
        case offerSaleSteps.saleFailed:
          navigate("/");
          break;

        default:
          return;
      }
    } catch {
      // TODO hubertp: Toast with information about ethernet disconecting or any other error?
    }
    dispatch(finishAsyncOperation());
  };

  const _onGetCustomerRegistrationStatus = () => {
    dispatch(startAsyncOperation());

    getCustomerRegistrationStatus(additionalData.transactionId).then((result) => {
      if (!result.data) {
        setAdditionalData({ ...additionalData, customerRegistered: result.data });
      } else {
        if (isDeviceScanRequired) setFormStep(offerSaleSteps.scanDevice);
        else setFormStep(offerSaleSteps.saleConfirmation);
      }
      dispatch(finishAsyncOperation());
    });
  };

  return (
    <OfferSaleUI
      onGoBack={_onGoBack}
      formStep={formStep}
      control={control}
      onQrScanned={_onQrScanned}
      onSubmit={handleSubmit(_onSubmit)}
      additionalData={additionalData}
      setValue={setValue}
      onGetCustomerRegistrationStatus={_onGetCustomerRegistrationStatus}
      offers={offers}
    />
  );
};

export default memo(OfferSale);
