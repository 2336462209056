import Content from '../../components/ui/Content/Content'
import { SvgIcon } from '../../components/ui/SvgIcon/SvgIcon'
import styles from './Regulations.module.scss'
import langs from '../../common/services/languageService'
import { useNavigate } from 'react-router'
import { RegulationsTypes } from '../../common/consts/RegulationsTypes'
import {
  getOffersForRegulation,
  getRegulationLink,
} from '../../common/services/clerkService'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../app/hooks'
import {
  finishAsyncOperation,
  startAsyncOperation,
} from '../../common/commonSlice'
import AnimationBlock from '../../components/ui/AnimationBlock/AnimationBlock'
import { SimpleItem } from '../../common/models/SimpleItem'
import Link from '../../components/ui/Link/Link'

const { labels } = langs

export function Regulations() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [offers, setOffers] = useState<SimpleItem[]>()
  const onGoBack = () => {
    navigate('/')
  }
  useEffect(() => {
    dispatch(startAsyncOperation())
    getOffersForRegulation()
      .then((resp) => {
        setOffers(resp.data)
        dispatch(finishAsyncOperation())
      })
      .catch(() => {
        dispatch(finishAsyncOperation())
      })
  }, [])

  const getRegulationUrl = (
    type: RegulationsTypes,
    offerId: string | null
  ): string => {
    return getRegulationLink({ type, offerId })
  }

  const isLoaded = offers != undefined
  return (
    <>
      {isLoaded && (
        <Content
          icon={
            <SvgIcon
              icon={'user'}
              animation
            />
          }
          title={labels.Regulations()}
          goBackLabel={labels.Back().toLowerCase()}
          onGoBack={onGoBack}
        >
          <div className={styles.regulations}>
            <AnimationBlock
              animation="fade-in-left"
              animationDelay="0.2s"
            >
              <ul className={styles.regulationsList}>
                <li>
                  <Link
                    download
                    href={getRegulationUrl(
                      RegulationsTypes.quickAppGuide,
                      null
                    )}
                    className={styles.regulationItem}
                  >
                    {labels.QuickApplicationGuide()}
                  </Link>
                </li>
                <li>
                  <Link
                    download
                    href={getRegulationUrl(RegulationsTypes.platform, null)}
                    className={styles.regulationItem}
                  >
                    {labels.PlatformRegulations()}
                  </Link>
                </li>
                <li>
                  <Link
                    download
                    href={getRegulationUrl(
                      RegulationsTypes.informationClause,
                      null
                    )}
                    className={styles.regulationItem}
                  >
                    {labels.InformationClause()}
                  </Link>
                </li>
                <li>
                  <Link
                    download
                    href={getRegulationUrl(
                      RegulationsTypes.privacyPolicy,
                      null
                    )}
                    className={styles.regulationItem}
                  >
                    {labels.PrivacyPolicy()}
                  </Link>
                </li>
              </ul>
            </AnimationBlock>
            <AnimationBlock
              animation="fade-in-left"
              animationDelay="0.3s"
            >
              <h1 className={styles.offersRegulations}>
                {labels.OffersRegulations()}
              </h1>
            </AnimationBlock>
            {offers.length > 0 && (
              <AnimationBlock
                animation="fade-in-left"
                animationDelay="0.4s"
              >
                <ul className={styles.regulationsList}>
                  {offers.map((offer) => (
                    <li key={offer.id}>
                      <Link
                        download
                        href={getRegulationUrl(
                          RegulationsTypes.programForClerk,
                          offer.id.toString()
                        )}
                        className={styles.regulationItem}
                      >
                        {offer.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </AnimationBlock>
            )}
            <SvgIcon
              height="50px"
              animationDelay={500}
              className={styles.ploomLogo}
              icon="logo"
              customColor="#fff"
              animation={true}
            />
          </div>
        </Content>
      )}
    </>
  )
}
