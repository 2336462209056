import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import UserDictionaryTypes from "./consts/UserDictionaryTypes";

export interface DictionaryState {
  countries: [];
  voivodeships: [];
  taxOffices: [];
}

const initialState: DictionaryState = {
  countries: [],
  voivodeships: [],
  taxOffices: [],
};

export const dictionarySlice = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    setDictionary: (
      state,
      action: PayloadAction<{ type: UserDictionaryTypes; items: [] }>
    ) => {
      switch (action.payload.type) {
        case UserDictionaryTypes.countries:
          state.countries = action.payload.items;
          break;
        case UserDictionaryTypes.taxOffices:
          state.taxOffices = action.payload.items;
          break;
        case UserDictionaryTypes.voivodeships:
          state.voivodeships = action.payload.items;
          break;
      }
    },
  },
});

export const { setDictionary } = dictionarySlice.actions;

export const selectCountries = (state: RootState) =>
  state.dictionaries.countries;
export const selectVoivodeships = (state: RootState) =>
  state.dictionaries.voivodeships;
export const selectTaxOffices = (state: RootState) =>
  state.dictionaries.taxOffices;

export default dictionarySlice.reducer;
