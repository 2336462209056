import React, { memo } from "react";
import { Offer } from "../../../common/models/Offer";
import SelectOfferUI from "./SelectOfferUI";
import { Control, UseFormSetValue } from "react-hook-form";
import { OfferSaleType } from "../../../common/dataTypes/OfferSaleType";

interface SelectOfferProps {
  control: Control<any>;
  additionalData: any;
  onSubmit: () => void;
  setValue: UseFormSetValue<OfferSaleType>;
  offers: Offer[];
}

const SelectOffer: React.FC<SelectOfferProps> = ({ additionalData, onSubmit, setValue, offers }) => {
  const _onSelectOffer = (selectedOffer: Offer) => {
    setValue("offer", selectedOffer);
    onSubmit();
  };

  return <SelectOfferUI offers={offers} onSelectOffer={_onSelectOffer} />;
};

export default memo(SelectOffer);
