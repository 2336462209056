import React, { memo, useState } from "react";
import { SvgIcon } from "../../../components/ui/SvgIcon/SvgIcon";
import styles from "../OfferSale.module.scss";
import ButtonWithConfirmation from "../../../components/ui/ButtonWithConfirmation/ButtonWithConfirmation";

import langs from "../../../common/services/languageService";
import Button from "../../../components/ui/Button/Button";
const { labels } = langs;
interface WaitForRegistrationProps {
  onAbortProcess: () => void;
  onGetCustomerRegistrationStatus: () => void;
  additionalData: any;
}

const WaitForRegistration: React.FC<WaitForRegistrationProps> = ({ onAbortProcess, additionalData, onGetCustomerRegistrationStatus }) => {
  const timeToShowGetDataButton = 15000;
  const [getDataButtonVisible, setGetDataButtonVisible] = useState(false);

  setInterval(() => {
    setGetDataButtonVisible(true);
  }, timeToShowGetDataButton);

  return (
    <>
      <div className={styles.sucessStep}>
        <div>
          <SvgIcon icon="hourglass" animation height="100px" />
        </div>
        <div>{labels.WaitForClientRegisterMessage()}</div>
        <div>{labels.OfferSaleCancelation()}</div>
      </div>
      <ButtonWithConfirmation
        onConfirm={onAbortProcess}
        confirmationMessage={labels.CancelCustomerRegistrationProcessQuestion()}
        confirmLabel={labels.Yes()}
        cancelLabel={labels.No()}>
        {labels.AbortProcess().toLowerCase()}
      </ButtonWithConfirmation>
      <div className={styles.fetchRegistrationConfirmation}>
        {getDataButtonVisible && (
          <>
            <Button onClick={onGetCustomerRegistrationStatus} type="button">{labels.Refresh().toLowerCase()}</Button>
            {additionalData.customerRegistered === false && <span>{labels.CustomerNotYetRegistered()}</span>}
          </>
        )}
      </div>
    </>
  );
};

export default memo(WaitForRegistration);
