import clsx from "clsx";
import React, { ReactElement, memo, useEffect, useRef } from "react";
import styles from "./QrScanner.module.scss";
import QrScannerLib from "qr-scanner";

interface QrScannerProps {
	onScannedSuccesful: (data: string) => void;
	additionalElement?: ReactElement;
}

const QrScanner: React.FC<QrScannerProps> = ({ onScannedSuccesful, additionalElement }) => {
	const videoElem = useRef<HTMLVideoElement>(null);
	const overlayElem = useRef<HTMLDivElement>(null);

	useEffect(() => {
		var qrScanner = new QrScannerLib(
			videoElem.current as HTMLVideoElement,
			(result: any) => {
				onScannedSuccesful(result.data);
				qrScanner.stop();
			},
			{
				preferredCamera: "environment",
				highlightScanRegion: true,
				highlightCodeOutline: true,
				overlay: overlayElem.current as HTMLDivElement,
			},
		);
		qrScanner.start();
		return () => {
			qrScanner.stop();
		};
	}, []);

	return (
		<div className={styles.qrScanner}>
			<video className={clsx(styles.video)} ref={videoElem}></video>
			{additionalElement}
		</div>
	);
};

export default memo(QrScanner);
