import React, { memo } from "react";
import { Control, FieldError } from "react-hook-form";
import { OfferSaleType } from "../../../common/dataTypes/OfferSaleType";
import TextBoxWithQrScanner from "../../../components/ui/TextBoxWithQrScanner/TextBoxWithQrScanner";
import Button from "../../../components/ui/Button/Button";
import styles from "../OfferSale.module.scss";
import ValidationLabel from "../../../components/ui/ValidationLabel/ValidationLabel";
import { offerSaleErrors } from "../OfferSale";

import langs from "../../../common/services/languageService";
const {labels, validationMessages} = langs;

interface ScanDeviceProps {
	control: Control<OfferSaleType, any>;
	onQrScanned: (controlName: string, value: string) => void;
	onSubmit: () => void;
}

const ScanDevice: React.FC<ScanDeviceProps> = ({ control, onQrScanned, onSubmit }) => {
	const serverError = offerSaleErrors.value.root?.serverError;
	return (
		<>
			<div className={styles.scanDevice}>
				<span>{labels.CustomerAlreadyRegistered()}</span>
				<span>{labels.ScanOrEnterTheQrCodeOfTheDevice()}</span>
				<TextBoxWithQrScanner 
					name="deviceQRCode" 
					label="Kod QR urządzenia" 
					control={control} 
					onQrScanned={onQrScanned} 
					showScannerByDefault 
					validationRule={{required: validationMessages.Required()}}
				/>
			</div>
			<ValidationLabel asBigText centered error={serverError as FieldError} className={styles.paddingTop}/>
			<Button onClick={onSubmit}>{labels.Confirm().toLowerCase()}</Button>
		</>
	);
};

export default memo(ScanDevice);
