import { Offer } from '../../../common/models/Offer'
import Button from '../Button/Button'
import styles from './OfferCard.module.scss'
import langs from '../../../common/services/languageService'
import { useEffect, useRef, useState } from 'react'
import Image from '../Image/Image'
import clsx from 'clsx'
import Card from '../Card/Card'

const { labels } = langs

type OfferCardProps = {
  offer: Offer
  showDescription?: boolean
  onClick?: (selectedOffer: Offer) => void
}

export function OfferCard({ offer, showDescription, onClick }: OfferCardProps) {

  const _onClick = onClick != undefined ? () => {
    if (onClick) onClick(offer)
  } : undefined;

  return (
    <Card
      header={offer.name}
      description={showDescription ? offer.description : undefined}
      onClick={_onClick}
      image={offer.picture}
      showMoreLabel={labels.ShowMore().toLowerCase()}
      hideMoreLabel={labels.ShowLess().toLowerCase()}
    />
  )
}
