import { ChangePasswordResult } from "../models/ChangePasswordResult";
import { ChangePasswordValues } from "../../features/ChangePassword/ChangePassword";
import { ClerkBalanceDataType } from "../dataTypes/ClerkType";
import { ClerkLoginResult } from "../models/ClerkLoginResult";
import { EditClerkResult } from "../models/EditClerkResult";
import { GetPointOfSaleResult } from "../models/GetPointOfSaleResult";
import { LoginFormValues } from "../../features/Login/Login/Login";
import { ProfileValues } from "../../features/Profile/Profile";
import { RegisterClerkResult } from "../models/RegisterClerkResult";
import { ResetPasswordResult } from "../models/ResetPasswordResult";
import { ResetPasswordValues } from "../../features/ResetPassword/ResetPassword";
import { ValidateEmailResult } from "../models/ValidateEmailResult";
import axios from "axios";
import DictionaryTypes from "../consts/DictionaryTypes";
import { RegulationsTypes } from "../consts/RegulationsTypes";
import { Offer } from "../models/Offer";
import { ValidateCustomerResp } from "../models/ValidateCustomerResp";
import { SelectOfferResp } from "../models/SelectOfferResp";
import { DeviceRegistrationResult } from "../models/DeviceRegistrationResult";
import { SimpleItem } from "../models/SimpleItem";
import { ClerkTokenRequest } from "../models/ClerkTokenRequest";
import { ClerkRewardProgram } from "../models/ClerkRewardProgram";

const baseUrl = (window as any).config.apiUrl;

const axiosAutorizedCalls = axios.create();

// Request interceptor for API calls
axiosAutorizedCalls.interceptors.request.use(
  async config => {
    const token = localStorage.getItem("token");
    config.headers = {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    } as any

    return config;
  },
  error => {
    Promise.reject(error)
  });

// Response interceptor for API calls
axiosAutorizedCalls.interceptors.response.use((response) => {
  return response
}, async function (error) {

  const now = new Date(Date.now());
  const originalRequest = error.config;
  const refreshTokenExpire = localStorage.getItem('refreshTokenExpire');

  if(refreshTokenExpire && refreshTokenExpire != '' && new Date(refreshTokenExpire) < now) {
    localStorage.clear();
    window.location.href = '/login';
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    var tokenRequest: ClerkTokenRequest = {
      email: localStorage.getItem("email")!,
      refreshToken: localStorage.getItem("refreshToken")!,
      rememberMe: localStorage.getItem("rememberMe") == "true",
      userId: localStorage.getItem("userId")!,
    };
    try {
      const access_token = await refreshToken(tokenRequest);

      localStorage.setItem("token", access_token.data.accessToken);
      localStorage.setItem("accessTokenExpire", access_token.data.accessTokenExpire.toString());
      localStorage.setItem("refreshToken", access_token.data.refreshToken);
      localStorage.setItem("refreshTokenExpire", access_token.data.refreshTokenExpire.toString());

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return axiosAutorizedCalls(originalRequest);
    }
    catch {
      localStorage.clear();
      window.location.href = '/login';
    }
  }

  return Promise.reject(error);
});

export const validateEmail = (model: { email: string, captchaKey: string | undefined }) => {
  return axiosAutorizedCalls.post<ValidateEmailResult>(baseUrl + 'clerk/validate-email', model);
}

export const validateCustomer = (model: { phoneNumber: string, clerkId: number, qrCode: string }) => {
  return axiosAutorizedCalls.post<ValidateCustomerResp>(`${baseUrl}clerk/customer-validate`, { ...model, channel: 50003 })
}

export const registerClerk = (model: ProfileValues) => {
  return axiosAutorizedCalls.post<RegisterClerkResult>(baseUrl + 'clerk/register', model);
}

export const login = (model: LoginFormValues) => {
  return axios.post<ClerkLoginResult>(baseUrl + 'clerk/login', model);
}

export const getUserDictionary = (dictionaryId: number) => {
  return axiosAutorizedCalls.get<[]>(baseUrl + `dictionary/${DictionaryTypes.UserDictionary}/${dictionaryId}`)
}

export const resetPassword = (model: ResetPasswordValues) => {
  return axiosAutorizedCalls.post<ResetPasswordResult>(baseUrl + 'clerk/reset-password', model);
}

export const sendResetPassword = (model: { email: string, captchaKey: string }) => {
  return axiosAutorizedCalls.patch(baseUrl + 'clerk/send-reset-password', model);
}

export const getPointOfSale = (rtNumber: string) => {
  return axiosAutorizedCalls.get<GetPointOfSaleResult>(baseUrl + 'pointOfSale/' + rtNumber);
}

export const changePassword = (model: ChangePasswordValues) => {
  return axiosAutorizedCalls.post<ChangePasswordResult>(baseUrl + 'clerk/change-password', model);
}

export const getClerkData = () => {
  return axiosAutorizedCalls.get<ProfileValues>(baseUrl + 'clerk/my-data');
}

export const editClerk = (model: ProfileValues) => {
  return axiosAutorizedCalls.put<EditClerkResult>(baseUrl + 'clerk', model);
}

export const getClerkBalanceData = () => {
  return axiosAutorizedCalls.get<ClerkBalanceDataType>(`${baseUrl}clerk/my-balance-data`);
}

export const getRegulationLink = (model: { type: RegulationsTypes, offerId: string | null }): string => {
  const offerIdParam = model.offerId ? `?offerId=${model.offerId}` : "";

  return `files/${model.type}${offerIdParam}`;
}

export const getOffers = () => {
  return axiosAutorizedCalls.get<Offer[]>(`${baseUrl}clerk/offers`);
}

export const getClerkRewardPrograms = () => {
  return axiosAutorizedCalls.post<ClerkRewardProgram[]>(`${baseUrl}clerk/my-reward-programs/`)
}

export const getSignUpToPointOfSale = (model: { clerkId: number, qrCode: string }) => {
  return axiosAutorizedCalls.post<boolean>(`${baseUrl}clerk/sign-up-to-point-of-sale`, model);
}

export const selectOffer = (model: { transactionId: string, phoneNumber: string, offerId: number }) => {
  return axiosAutorizedCalls.post<SelectOfferResp>(`${baseUrl}clerk/select-offer`, model);
}

export const cancelTransaction = (model: { transactionId: string, phoneNumber: string, clerkId: number }) => {
  return axiosAutorizedCalls.post<boolean>(`${baseUrl}clerk/cancel-transaction`, model);
}

export const confirmSale = (model: { transactionId: string, phoneNumber: string, deviceNumber?: string }) => {
  return axiosAutorizedCalls.post<DeviceRegistrationResult>(`${baseUrl}clerk/confirm-purchase`, { ...model, channel: 50003 });
}

export const confirmRegistration = (email: string, code: string) => {
  return axiosAutorizedCalls.get<boolean>(`${baseUrl}clerk/confirm-registration/${email}/${code}`);
}

export const getOffersForRegulation = () => {
  return axiosAutorizedCalls.get<SimpleItem[]>(`${baseUrl}clerk/regulations-offers`);
}

export const getCustomerRegistrationStatus = (transactionId: string) => {
  return axiosAutorizedCalls.get<boolean>(`${baseUrl}clerk/is-customer-registred/${transactionId}`);
}

export const refreshToken = (model: ClerkTokenRequest) => {
  return axios.post<ClerkLoginResult>(baseUrl + 'clerk/refresh-token', model)
}