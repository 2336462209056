import React, { memo, useEffect, useRef } from 'react'
import { FieldValues, useController } from 'react-hook-form'
import ValidationLabel from '../ui/ValidationLabel/ValidationLabel'
import { clsx } from 'clsx'
import { formatFieldLabel } from '../../common/helpers/formHelper'
import ReCAPTCHA from 'react-google-recaptcha'
import styles from './ReCaptchaField.module.scss'
import langs from '../../common/services/languageService'
const { validationMessages } = langs

type ReCaptchaProps = {
  className?: string,
  onReCaptchaChange?: (key: string) => void
}

function ReCaptchaField({
  name,
  control,
  rules,
  ...props
}: FieldValues & ReCaptchaProps) {
  const defaultRules = { required: validationMessages.Required() }
  const { field, fieldState } = useController({
    name,
    control,
    rules: rules ?? defaultRules,
    disabled: props.disabled,
  })
  const captchaRef = useRef<any>()

  useEffect(() => {
    if (field.value == '') {
      captchaRef.current.reset()
    }
  }, [field.value])

  const onReCaptchaChange = (key: string | null) => {
    field.onChange(key)
  }

  return (
    <div className={clsx(styles.container, props.className)}>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={(window as any).config.captchaKey}
        theme="dark"
        hl="pl"
        onChange={onReCaptchaChange}
      />
      <ValidationLabel error={fieldState.error} />
    </div>
  )
}

export default memo(ReCaptchaField)
