import { Info, InfoType } from "../../components/ui/Info/Info";
import { SubmitHandler, useForm } from "react-hook-form";
import { SvgIcon } from "../../components/ui/SvgIcon/SvgIcon";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Button from "../../components/ui/Button/Button";
import Content from "../../components/ui/Content/Content";
import styles from "./ChangePassword.module.scss";
import { passwordRegex } from "../../common/helpers/validationHelper";
import { changePassword } from "../../common/services/clerkService";
import { useAppSelector } from "../../app/hooks";
import {
  clerkIdSelector,
  finishAsyncOperation,
  startAsyncOperation,
} from "../../common/commonSlice";
import PasswordInput from "../../components/ui/PasswordInput/PasswordInput";
import langs from "../../common/services/languageService";
import AnimationBlock from "../../components/ui/AnimationBlock/AnimationBlock";
import { useDispatch } from "react-redux";

const { labels, validationMessages, templates } = langs;

export type ChangePasswordValues = {
  clerkId: number | undefined;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

enum ChangePasswordStep {
  changePassword,
  info,
}

export function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(ChangePasswordStep.changePassword);
  const clerkId = useAppSelector(clerkIdSelector);
  const onGoBack = () => {
    navigate("/");
  };
  const {
    control,
    formState: { errors, isValid, isSubmitted },
    handleSubmit,
    setValue,
    watch,
    getValues,
    setError,
  } = useForm<ChangePasswordValues>({
    mode: "onChange",
    criteriaMode: "all",
  });
  const validationRules = {
    oldPassword: {
      required: validationMessages.Required(),
      maxLength: {
        value: 200,
        message: validationMessages.MaximumLength().replace("{0}", "200"),
      },
    },
    newPassword: {
      required: validationMessages.Required(),
      pattern: {
        value: passwordRegex,
        message: validationMessages.PasswordRequirements(),
      },
      maxLength: {
        value: 200,
        message: validationMessages.MaximumLength().replace("{0}", "200"),
      },
      validate: (val: string) => {
        if (watch("oldPassword") == val) {
          return validationMessages.PasswordCanotBeSame();
        }
      },
    },
    confirmPassword: {
      required: validationMessages.Required(),
      validate: (val: string) => {
        if (watch("newPassword") != val) {
          return validationMessages.PasswordNotMatch();
        }
      },
      maxLength: {
        value: 200,
        message: validationMessages.MaximumLength().replace("{0}", "200"),
      },
    },
  };
  const onSubmit: SubmitHandler<ChangePasswordValues> = (data) => {
    const payload = { ...data, clerkId: clerkId };

    dispatch(startAsyncOperation());
    changePassword(payload)
      .then((resp) => {
        dispatch(finishAsyncOperation());
        if (resp.data.isPasswordSuccessfullyChanged) {
          setStep(ChangePasswordStep.info);
        } else if (!resp.data.isOldPasswordValid)
          setError("oldPassword", {
            type: "custom",
            message: validationMessages.WrongPassword(),
          });
      })
      .catch(() => {
        dispatch(finishAsyncOperation());
        //TODO: Nie do końca wiadomo co się ma stać
      });
  };

  return (
    <>
      {step == ChangePasswordStep.changePassword && (
        <Content
          className={styles.changePassword}
          icon={<SvgIcon icon={"user"} />}
          title={labels.ChangePassword()}
          goBackLabel={labels.Back().toLowerCase()}
          onGoBack={() => navigate("/")}
        >
          <div className={styles.changePassword_container}>
            <SvgIcon
              className={styles.ploomLogo}
              icon="logo"
              customColor="#fff"
              animation={true}
            />
            <AnimationBlock>
              <form
                className={styles.changePassword_form}
                onSubmit={handleSubmit(onSubmit)}
              >
                <PasswordInput
                  name="oldPassword"
                  control={control}
                  rules={validationRules.oldPassword}
                  label={labels.OldPasswrod().toLowerCase()}
                  passwordType="current-password"
                />
                <PasswordInput
                  name="newPassword"
                  control={control}
                  rules={validationRules.newPassword}
                  label={labels.NewPassword().toLowerCase()}
                  passwordType="new-password"
                />
                <PasswordInput
                  name="confirmPassword"
                  control={control}
                  rules={validationRules.confirmPassword}
                  label={labels.ConfirmPassword()}
                  passwordType="new-password"
                />
                <div className={styles.button_wrapper}>
                  <Button buttonType="primary" size="md" type="submit">
                    {labels.Save().toLowerCase()}
                  </Button>
                </div>
              </form>
            </AnimationBlock>
          </div>
        </Content>
      )}
      {step == ChangePasswordStep.info && (
        <Info
          hasIcon={true}
          type={InfoType.success}
          header={labels.ChangePassword()}
          onGoBack={onGoBack}
        >
          <div className={styles.restorePasswordInfo}>
            {labels.ChangePasswordSuccessInfo()}
          </div>
        </Info>
      )}
    </>
  );
}
