import React, { ChangeEvent, MouseEventHandler, RefObject, memo, useCallback, useState } from 'react';
import styles from './Checkbox.module.scss';
import clsx from 'clsx';


export interface CheckboxProps {
    id?: string;
    className?: string;
    refs?: any;
    onChange?: (value: Boolean) => void;
    hasError?: boolean;
    value?: boolean;
    disabled?: boolean;
    label?: React.ReactElement | string;
    labelAlign?: "left" | "right";
};



function Checkbox({ refs, value, onChange, hasError, id, className, disabled, label, labelAlign="left" } : CheckboxProps) {

    const [valueState, setValueState] = useState(value);
    const [isFocused, setIsFocused] = useState(false);

    const onLocalChange = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
        if(disabled) return;

        setValueState(e?.currentTarget.checked);
        if(onChange)
            onChange(e?.currentTarget.checked);

    }, [disabled, onChange]);

    const onMouseUp = useCallback((e : React.MouseEvent<HTMLInputElement>) => {
        setIsFocused(false);
    }, []);

    const onFocus = (e : React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
    };

    const onBlur = (e : React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
    };

    return (
        <div className={clsx(styles.container, valueState && styles.checked, isFocused && styles.focused, disabled && styles.disabled ,hasError && styles.validationError, labelAlign && styles[`label-align--${labelAlign}`])}>
            <div className={styles.checkbox}>
                <input
                    ref={refs}
                    className={`${styles.input} ${className}`}
                    type="checkbox"
                    id={id}
                    onClick={onLocalChange}
                    onMouseUp={onMouseUp}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                <div className={styles.box}>
                    <svg className={clsx(styles.checkMark)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                        <path className="tick" fill="none" strokeLinecap="round" strokeWidth="4" d="M4 10l5 5 9-9" />
                    </svg>
                </div>
            </div>
            {label && <label htmlFor={id}>{label}</label>}
        </div>
    );
}

export default memo(Checkbox);
