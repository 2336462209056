/* eslint-disable import/no-anonymous-default-export*/
// eslint-disable import/no-anonymous-default-export
// File was autogenerated by tools/generateLabels.js script
import labels from  '../consts/resources/labelsResources';
import templates from  '../consts/resources/templatesResources';
import validationMessages from  '../consts/resources/validationMessagesResources';

export default {
    labels: labels,
    validationMessages: validationMessages,
    templates: templates,
};
