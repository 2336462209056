import Content from "../../components/ui/Content/Content";
import { SvgIcon } from "../../components/ui/SvgIcon/SvgIcon";
import langs from "../../common/services/languageService";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ResetPassword.module.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { passwordRegex } from "../../common/helpers/validationHelper";
import TextBoxField from "../../components/form/TextBoxField";
import Button from "../../components/ui/Button/Button";
import { login, refreshToken, resetPassword } from "../../common/services/clerkService";
import { finishAsyncOperation, setLoginData, startAsyncOperation } from "../../common/commonSlice";
import { useAppDispatch } from "../../app/hooks";
import { LoginFormValues } from "../Login/Login/Login";
import PasswordInput from "../../components/ui/PasswordInput/PasswordInput";
import AnimationBlock from "../../components/ui/AnimationBlock/AnimationBlock";
import ReCaptchaField from "../../components/form/ReCaptchaField";
import { ClerkTokenRequest } from "../../common/models/ClerkTokenRequest";

const { labels, validationMessages } = langs;

export type ResetPasswordValues = {
  newPassword: string;
  confirmPassword: string;
  code: string | undefined;
  email: string | undefined;
  captchaKey: string;
};

export function ResetPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { email, code } = useParams();

  const {
    control,
    formState: { errors, isValid, isSubmitted },
    handleSubmit,
    setValue,
    watch,
    getValues,
    setError,
  } = useForm<ResetPasswordValues>({
    mode: "onChange",
    criteriaMode: "all",
  });
  const validationRules = {
    newPassword: {
      required: validationMessages.Required(),
      pattern: {
        value: passwordRegex,
        message: validationMessages.PasswordRequirements(),
      },
    },
    confirmPassword: {
      required: validationMessages.Required(),
      validate: (val: string) => {
        if (watch("newPassword") != val) {
          return validationMessages.PasswordNotMatch();
        }
      },
    },
    captchaKey: {
      required: validationMessages.Required(),
    },
  };
  const onSubmit: SubmitHandler<ResetPasswordValues> = (data) => {
    let payload = { ...data, code: code, email: email };

    dispatch(startAsyncOperation());
    resetPassword(payload)
      .then((resp) => {
        if (resp.data.isPasswordSuccessfullyChanged) {
          const loginPayload: LoginFormValues = {
            email: email as string,
            password: data.newPassword,
            rememberMe: false,
            captchaKey: payload.captchaKey,
          };
          login(loginPayload).then((resp) => {
            var tokenRequest: ClerkTokenRequest = {
              ...resp.data,
              email: loginPayload.email,
              refreshToken: resp.data.refreshToken,
              rememberMe: loginPayload.rememberMe,
              userId: resp.data.userId,
            };

            refreshToken(tokenRequest).then((resp) => {
              dispatch(setLoginData(resp.data));
              localStorage.setItem("rememberMe", loginPayload.rememberMe.toString());
              dispatch(finishAsyncOperation());
              navigate("/");
            });
          });
        } else {
          setValue("captchaKey", "");
          dispatch(finishAsyncOperation());
          setError("newPassword", {
            type: "custom",
            message: validationMessages.PasswordCanotBeSame(),
          });
        }
      })
      .catch(() => {
        dispatch(finishAsyncOperation());
        navigate("/failed");
      });
  };

  return (
    <Content
      icon={<SvgIcon icon={"user"} />}
      title={labels.ChangePassword()}
      goBackLabel={labels.Back().toLowerCase()}
      onGoBack={() => navigate("/")}>
      <div className={styles.resetPassword_container}>
        <SvgIcon className={styles.ploomLogo} icon="logo" customColor="#fff" animation={true} />
        <form className={styles.resetPassword_form} onSubmit={handleSubmit(onSubmit)}>
          <AnimationBlock animationDelay="0.5s">
            <PasswordInput
              name="newPassword"
              control={control}
              rules={validationRules.newPassword}
              label={labels.Password()}
            />
            <PasswordInput
              name="confirmPassword"
              control={control}
              rules={validationRules.confirmPassword}
              label={labels.ConfirmPassword()}
            />
            <div className={styles.buttons}>
              <Button buttonType="primary" size="md" type="submit">
                {labels.Save().toLowerCase()}
              </Button>
            </div>
          </AnimationBlock>
        </form>
        <AnimationBlock animationDelay="0.7s">
          <ReCaptchaField control={control} name="captchaKey" className={styles.recaptcha} />
        </AnimationBlock>
      </div>
    </Content>
  );
}
