import Content from "../../components/ui/Content/Content";
import { SvgIcon } from "../../components/ui/SvgIcon/SvgIcon";
import langs from "../../common/services/languageService";
import { useNavigate } from "react-router-dom";
import styles from "./ForgotPassword.module.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { emailRegex } from "../../common/helpers/validationHelper";
import TextBoxField from "../../components/form/TextBoxField";
import Button from "../../components/ui/Button/Button";
import { sendResetPassword } from "../../common/services/clerkService";
import { useRef, useState } from "react";
import { Info, InfoType } from "../../components/ui/Info/Info";
import Row from "../../components/ui/FormLayout/Row";
import Col from "../../components/ui/FormLayout/Col";
import FormLayout from "../../components/ui/FormLayout/FormLayout";
import { finishAsyncOperation, startAsyncOperation } from "../../common/commonSlice";
import { useAppDispatch } from "../../app/hooks";
import AnimationBlock from "../../components/ui/AnimationBlock/AnimationBlock";
import ReCaptchaField from "../../components/form/ReCaptchaField";
import { Portal } from "../../components/ui/Portal/Portal";
import Image from "../../components/ui/Image/Image";
import ploom_device from "../../styles/images/ploom_device.png";

const { labels, validationMessages, templates } = langs;

export type SendResetPasswordValues = {
  email: string;
  repeatEmail: string;
  captchaKey: string;
};

enum ForgotPasswordStep {
  sendEmail,
  info,
}

export function ForgotPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(ForgotPasswordStep.sendEmail);
  const onGoBack = () => {
    navigate("/login");
  };

  const {
    control,
    formState: { errors, isValid, isSubmitted },
    handleSubmit,
    setValue,
    watch,
    getValues,
    clearErrors,
    setError,
  } = useForm<SendResetPasswordValues>({
    mode: "onChange",
    criteriaMode: "all",
  });

  const validationRules = {
    email: {
      required: validationMessages.Required(),
      pattern: {
        value: emailRegex,
        message: validationMessages.Email(),
      },
      maxLength: {
        value: 200,
        message: validationMessages.MaximumLength().replace("{0}", "200"),
      },
    },
    repeatEmail: {
      required: validationMessages.Required(),
      validate: (val: string) => {
        if (watch("email") != val) {
          return validationMessages.EmailNotMatch();
        }
      },
    },
  };

  const onSubmit: SubmitHandler<SendResetPasswordValues> = (data) => {
    let payload = { email: data.email, captchaKey: data.captchaKey };
    dispatch(startAsyncOperation());

    sendResetPassword(payload)
      .then(() => {
        dispatch(finishAsyncOperation());
        setStep(ForgotPasswordStep.info);
      })
      .catch(() => {
        dispatch(finishAsyncOperation());
        setValue("captchaKey", "");
        //TODO: Nie do końca wiadomo co się ma stać
      });
  };

  return (
    <>
      {step == ForgotPasswordStep.sendEmail && (
        <Content
          icon={<SvgIcon icon={"user"} />}
          title={labels.RestorePassword()}
          goBackLabel={labels.Back().toLowerCase()}
          onGoBack={onGoBack}
          className={styles.forgotPassword}>
          <FormLayout className={styles.forgotPasswordForm} onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <SvgIcon className={styles.ploomLogo} icon="logo" customColor="#fff" animation={true} />

                <AnimationBlock animationDelay="0.5s">
                  <TextBoxField
                    name="email"
                    control={control}
                    rules={validationRules.email}
                    label={labels.YourEmail()}
                  />
                  <TextBoxField
                    name="repeatEmail"
                    control={control}
                    rules={validationRules.repeatEmail}
                    label={labels.RepeatEmail()}
                  />
                </AnimationBlock>
                <AnimationBlock animationDelay="0.7s">
                  <div className={styles.buttons}>
                    <Button buttonType="primary" size="md" type="submit">
                      {labels.SendLink().toLowerCase()}
                    </Button>
                  </div>
                </AnimationBlock>
                <AnimationBlock animationDelay="1.1s">
                  <ReCaptchaField name="captchaKey" control={control} className={styles.recaptcha} />
                </AnimationBlock>
              </Col>
            </Row>
          </FormLayout>
          <Portal>
            <Image src={ploom_device} className={styles.img} />
          </Portal>
        </Content>
      )}
      {step == ForgotPasswordStep.info && (
        <Info hasIcon={true} type={InfoType.success} header={labels.RestorePassword()} onGoBack={onGoBack}>
          <div className={styles.restorePasswordInfo}>{labels.RestorePasswordInfo()}</div>
        </Info>
      )}
    </>
  );
}
