import React, { memo, useState } from "react";
import clsx from "clsx";
import styles from "./Image.module.scss";
interface ImageProps {
	src?: any;
    className?: string;
}

const Image: React.FC<ImageProps> = ({ src, className }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [hasError, setHasError] = useState(false);
	
	const _onLoad = () => {
		setIsLoaded(true);
	};
	const _onError = () => {
		setHasError(true);
	};
	if(hasError)return <div className={clsx(styles.error, className)}>Image can not be loaded.</div>
	return <img onLoad={_onLoad} onError={_onError} src={src} alt="" className={clsx(className, isLoaded && "loaded")}/>;
};

export default memo(Image);
