import { Control } from 'react-hook-form'
import TextBoxField from '../../../components/form/TextBoxField'
import langs from '../../../common/services/languageService'
import { postalCode } from '../../../common/helpers/validationHelper'
import Button from '../../../components/ui/Button/Button'
import styles from './LegalEntity.module.scss'
import { ProfileFormModes, ProfileValues } from '../Profile'
import { FieldModes } from '../../../common/consts/FieldModes'

const { labels, validationMessages } = langs

type Props = {
  control: Control<ProfileValues, any>
  getSalePointData: (e: any) => void
  fieldMode: FieldModes
  formMode: ProfileFormModes
}

const validationRules = {
  nip: { required: validationMessages.Required() },
  companyName: {
    required: validationMessages.Required(),
    minLength: {
      value: 2,
      message: validationMessages.MinimumLength().replace('{0}', '2'),
    },
    maxLength: {
      value: 100,
      message: validationMessages.MaximumLength().replace('{0}', '100'),
    },
  },
  street: {
    required: validationMessages.Required(),
    minLength: {
      value: 2,
      message: validationMessages.MinimumLength().replace('{0}', '2'),
    },
    maxLength: {
      value: 100,
      message: validationMessages.MaximumLength().replace('{0}', '100'),
    },
  },
  buildingNumber: {
    required: validationMessages.Required(),
    maxLength: {
      value: 100,
      message: validationMessages.MaximumLength().replace('{0}', '100'),
    },
  },
  premisesNumber: {
    maxLength: {
      value: 100,
      message: validationMessages.MaximumLength().replace('{0}', '100'),
    },
  },
  city: {
    required: validationMessages.Required(),
    minLength: {
      value: 2,
      message: validationMessages.MinimumLength().replace('{0}', '2'),
    },
    maxLength: {
      value: 100,
      message: validationMessages.MaximumLength().replace('{0}', '100'),
    },
  },
  postalCode: {
    required: validationMessages.Required(),
    pattern: {
      value: postalCode,
      message: validationMessages.PostalCode(),
    },
  },
}

export function LegalEntity({ control, fieldMode, getSalePointData }: Props) {
  return (
    <>
      {fieldMode !== FieldModes.Read && (
        <Button
          onClick={(e) => getSalePointData(e)}
          className={styles.button}
        >
          {labels.GetPointOfSaleData().toLowerCase()}
        </Button>
      )}
      <div>
        <TextBoxField
          name="nip"
          isReadOnly
          control={control}
          rules={validationRules.nip}
          label={labels.Nip()}
        />
        <TextBoxField
          name="companyName"
          isReadOnly
          control={control}
          rules={validationRules.companyName}
          label={labels.CompanyName().toLowerCase()}
        />
      </div>
      <TextBoxField
        name="city"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.city}
        label={labels.CompanyCity().toLowerCase()}
      />
      <TextBoxField
        name="postalCode"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.postalCode}
        label={labels.CompanyPostalCode().toLowerCase()}
        inputProps={{ inputMode: 'text' }}
        mask="__-___"
        maskReplacement={{ _: /\d/ }}
      />
      <TextBoxField
        name="street"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.street}
        label={labels.CompanyStreet().toLowerCase()}
      />
      <TextBoxField
        name="buildingNumber"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.buildingNumber}
        label={labels.CompanyBuildingNumber().toLowerCase()}
      />
      <TextBoxField
        name="premisesNumber"
        isReadOnly={fieldMode == FieldModes.Read}
        control={control}
        rules={validationRules.premisesNumber}
        label={labels.CompanyApartmentNumber().toLowerCase()}
      />
    </>
  )
}
