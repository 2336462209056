import { SvgIcon } from "../../components/ui/SvgIcon/SvgIcon";
import styles from "./NotFound.module.scss";
import Button from "../../components/ui/Button/Button";
import { useNavigate } from "react-router-dom";
export function NotFound() {
	const navigate = useNavigate();
	const _onClick = () => {
		navigate("/");
	};
	return (
		<div className={styles.container}>
			<div className={styles.body}>
				<SvgIcon icon="circleXMark" customColor="red" height="100px" animation />
				<h2>Page not found!</h2>
				<p>
					<Button buttonType="secondary" onClick={_onClick}>
						Go to the home page
					</Button>
				</p>
			</div>
		</div>
	);
}
