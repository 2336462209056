import React, { memo } from 'react'
import styles from '../OfferSale.module.scss'
import langs from '../../../common/services/languageService'
import { SvgIcon } from '../../../components/ui/SvgIcon/SvgIcon'
import { Control } from 'react-hook-form'
import TextBoxField from '../../../components/form/TextBoxField'
import Button from '../../../components/ui/Button/Button'
const { validationMessages, labels } = langs

interface NoOfferProps {
  control: Control<any>
  onSubmit: () => void
}

const NoOffer: React.FC<NoOfferProps> = ({ control, onSubmit }) => {
  return (
    <>
      <div className={styles.selectOffer_noOffer}>
        <div className={styles.selectOffer_noOfferIcon}>
          <SvgIcon
            icon="circleXMark"
            height="100px"
            animation
            customColor="red"
          />
        </div>
        <div className={styles.selectOffer_validation}>
          {validationMessages.CurrentlyWeDoNotHavePromotialOfferForThisConsumer()}
        </div>
        <div className={styles.selectOffer_noOffer_form}>
          <TextBoxField
            name="phoneNumber"
            control={control}
            label={labels.PhoneNumber() + '*'}
            inputProps={{ inputMode: 'tel' }}
            mask="+48 ___ ___ ___"
            maskReplacement={{ _: /\d/ }}
          />
        </div>
      </div>
      <Button onClick={onSubmit}>{labels.Verify().toLowerCase()}</Button>
    </>
  )
}

export default memo(NoOffer)
