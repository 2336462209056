import React, { ReactElement, memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import HamburgerBtn from "../HamburgerBtn/HamburgerBtn";
import clsx from "clsx";
import { Portal } from "../../Portal/Portal";
import styles from "./HamburgerMenu.module.scss";
import { onLayoutScroll } from "../../../../features/Layout/layout";

interface HamburgerMenuProps {
	children?: ReactElement;
}

function hasClickedOnElement(element: HTMLDivElement | null, e: MouseEvent) {
	if (!element) return false;
	const elementRect = element.getBoundingClientRect();

	const startX = elementRect.x;
	const endX = elementRect.x + elementRect.width;
	const startY = elementRect.y;
	const endY = elementRect.y + elementRect.height;

	return e.x > startX && e.x < endX && e.y > startY && e.y < endY;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);
	const menuBtnRef = useRef<HTMLDivElement>(null);
	const _onClickButton = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		onLayoutScroll.value = _onLayoutScroll;
	},[]);

	const _onLayoutScroll = (e: React.UIEvent<HTMLDivElement>)=> {
		menuBtnRef?.current?.style.setProperty("top",`${(-e.currentTarget.scrollTop) + 25}px`)
	}
	const _onMouseClick = useCallback(
		(e: MouseEvent) => {
			const menuBtnHasClicked = hasClickedOnElement(menuBtnRef.current, e);
			const menuHasClicked = hasClickedOnElement(menuRef.current, e);

			if (!menuHasClicked && !menuBtnHasClicked) {
				setIsOpen(false);
				document.removeEventListener("click", _onMouseClick);
			}
		},
		[menuRef],
	);

	useLayoutEffect(() => {
		if (menuRef?.current && isOpen) document.addEventListener("click", _onMouseClick);
	}, [_onMouseClick, isOpen]);

	
	return (
		<>
			<Portal>
				<HamburgerBtn isOpen={isOpen} onClick={_onClickButton} refs={menuBtnRef} />

				<div ref={menuRef} className={clsx(styles.menu_portal, isOpen && styles.isOpen)}>
					{children && React.cloneElement(children, {isOpen: isOpen})}
				</div>
				<div className={clsx(styles.overlay, isOpen && styles.isOpen)}/>
			</Portal>
		</>
	);
};

export default memo(HamburgerMenu);
