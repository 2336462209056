import { clsx } from "clsx"
import styles from "./LoadingCounter.module.scss"
import { SvgIcon } from "../SvgIcon/SvgIcon"

export default function LoadingCounter() {
  return (
    <div className={styles.loadingCounter}>
      <div className={styles.loadingCounter__overlay}></div>
      <div className={styles.loadingCounter_googey}>
        <SvgIcon icon='logo' height="100px" className={styles.loadingCounter_logo} />
      </div>
    </div>
  )
}