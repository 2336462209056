import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import dictionarySlice from '../common/dictionariesSlice';
import commonSlice from '../common/commonSlice';

export const store = configureStore({
  reducer: {
    common: commonSlice,
    dictionaries: dictionarySlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
