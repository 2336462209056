/* eslint-disable import/no-anonymous-default-export*/
// eslint-disable import/no-anonymous-default-export
// File was autogenerated by tools/generateLabels.js script
import ResourceService from '../../services/resourceService';
const _ = (path:string) => ():string => ResourceService.get(path);

export default {
        ConsentsRequired: _('validationMessages.ConsentsRequired'),
        CurrentlyWeDoNotHavePromotialOfferForThisConsumer: _('validationMessages.CurrentlyWeDoNotHavePromotialOfferForThisConsumer'),
        Email: _('validationMessages.Email'),
        EmailNotMatch: _('validationMessages.EmailNotMatch'),
        FixedLength: _('validationMessages.FixedLength'),
        ForAdultOnly: _('validationMessages.ForAdultOnly'),
        MaximumLength: _('validationMessages.MaximumLength'),
        MinimumLength: _('validationMessages.MinimumLength'),
        NoPromotionOffer: _('validationMessages.NoPromotionOffer'),
        OfferNotAvailableMessage: _('validationMessages.OfferNotAvailableMessage'),
        PasswordCanotBeSame: _('validationMessages.PasswordCanotBeSame'),
        PasswordNotMatch: _('validationMessages.PasswordNotMatch'),
        PasswordRequirements: _('validationMessages.PasswordRequirements'),
        PhoneNumber: _('validationMessages.PhoneNumber'),
        PostalCode: _('validationMessages.PostalCode'),
        Required: _('validationMessages.Required'),
        SalePointAndRtNotMatch: _('validationMessages.SalePointAndRtNotMatch'),
        SalePointDoesntExist: _('validationMessages.SalePointDoesntExist'),
        UnknownErrorMessage: _('validationMessages.UnknownErrorMessage'),
        WrongDeviceNumber: _('validationMessages.WrongDeviceNumber'),
        WrongEmailOrAddress: _('validationMessages.WrongEmailOrAddress'),
        WrongPassword: _('validationMessages.WrongPassword'),
        WrongPesel: _('validationMessages.WrongPesel'),
        WrongSalePoint: _('validationMessages.WrongSalePoint'),
};
