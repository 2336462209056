import React, { memo, useState } from "react";
import { SvgIcon } from "../../components/ui/SvgIcon/SvgIcon";
import styles from "./Home.module.scss";
import ButtonLink from "../../components/ui/ButtonLink/ButtonLink";
import langs from "../../common/services/languageService";
import { ClerkBalanceDataType } from "../../common/dataTypes/ClerkType";
import { clsx } from "clsx";
import AnimationBlock from "../../components/ui/AnimationBlock/AnimationBlock";
import { IBeforeInstallPromptEvent } from "../../common/hooks/useAddToHomescreenPrompt";
import DevelopVersionInfo from "../../components/ui/DevelopVersionInfo/DevelopVersionInfo";
import { useAppSelector } from "../../app/hooks";
import { permissionsSelector } from "../../common/commonSlice";
import permissions from "../../common/consts/Permissions";
const { labels } = langs;

interface MenuProps {
  user?: ClerkBalanceDataType;
  onLogOut: () => void;
  className?: string;
  isOpen?: boolean;
  prompt: IBeforeInstallPromptEvent | null;
  promptToInstall: () => void;
}

const Menu: React.FC<MenuProps> = ({ user, onLogOut, className, isOpen, prompt, promptToInstall }) => {
  const userPermissions = useAppSelector(permissionsSelector);

  return (
    <div className={clsx(styles.menu, className && className)}>
      <AnimationBlock animate={isOpen} animation="fade-in-right" animationDelay="0.3s">
        <DevelopVersionInfo>
          <SvgIcon icon="user" height="40px" />
        </DevelopVersionInfo>
        <div className={styles.user}>
          <div className={styles.userName}>
            <h1>{user?.firstName ?? ""}</h1>
            <h2>{user?.lastName ?? ""}</h2>
          </div>
        </div>

        <div className={styles.menu_list}>
          <ButtonLink link="/offerSale">{labels.OfferSale().toLowerCase()}</ButtonLink>
          {userPermissions?.find((p) => p === permissions.RewardProgramAccess) && (
            <ButtonLink link="/programsForYou">{labels.ProgramsForYou().toLowerCaseFirstLetter()}</ButtonLink>
          )}
          <ButtonLink link="/profile">{labels.Profile().toLowerCase()}</ButtonLink>
          <ButtonLink link="/change-password">{labels.ChangePassword().toLowerCase()}</ButtonLink>
          <ButtonLink link="regulations">{labels.Regulations().toLowerCase()}</ButtonLink>
          {prompt && <ButtonLink onClick={() => promptToInstall()}>{labels.Install().toLowerCase()}</ButtonLink>}
          <ButtonLink onClick={onLogOut}>{labels.Logout().toLowerCase()}</ButtonLink>
        </div>
      </AnimationBlock>
    </div>
  );
};

export default memo(Menu);
