/* eslint-disable import/no-anonymous-default-export*/
// eslint-disable import/no-anonymous-default-export
// File was autogenerated by tools/generateLabels.js script

export default {
    Close: "Zamknij",
    ConsentsRequired: "Wymagane dane i zgody",
    CustomerAlreadyRegistred: "Wykonano już rejestrację korzystając z tego linku. Nie możesz skorzystać z niego ponownie.",
    CustomerRegistrationTimeout: "<div style=\"color: #bb3139\"><strong>Link wygasł</strong></br> przekroczono czas na\r\nuzupełnienie danych.\r\nJeśli chcesz skorzystać z oferty\r\nwejdź na Ploom.pl lub zapytaj\r\nsprzedawcę.</div>",
    DateOfBirth: "Data urodzenia",
    DealerCode: "Kod sklepu (zapytaj o niego sprzedawcę)",
    DeviceNumber: "Numer urządzenia",
    DoYouHaveAccount: "nie masz jeszcze konta?",
    Email: "Adres email",
    EnterPhoneNumberToVerifyAvailabilityOfPromotionalOffer: "Podaj swój numer telefonu. Sprawdzimy, \r\nczy jesteś uprawniony do udziału w Programie.",
    FirstName: "Imię",
    ForgotPassword: "zapomniałeś hasła?",
    LastName: "Nazwisko",
    Logging: "Logowanie",
    LogIn: "zaloguj",
    LoginPageGreetingsContinuation: "w aplikacji dla partnerów biznesowych <strong>JTI</strong>.",
    LoginPageLoginOrRegister: "Zaloguj się na swoje konto lub zarejestruj.",
    Next: "dalej",
    NoOffer: "Przepraszamy, oferta jest już nieaktualna",
    PhoneNumber: "Nr telefonu",
    Ploom: "Ploom",
    PloomRegistrationHeader: "Formularz rejestracyjny",
    Register: "Zarejestruj się",
    RegistrationFailure: "Rejestracja nie udana. Wpisano niepoprawny numer kodu lub kod został już wykorzystany",
    RegistrationSuccess: "<strong>Rejestracja zakończona pomyślnie!</strong>",
    RegistrationSuccessZabka: "<strong>Rejestracja zakończona pomyślnie!</strong><br/><br/>\r\nNa podany w formularzu numer \r\ntelefonu za chwilę otrzymasz kod \r\ndo wykorzystania w sklepach <strong>„Żabka”</strong>.",
    OfferRegulation: "Regulamin oferty",
    RememberMe: "zapamiętaj mnie",
    Save: "Zapisz",
    SearchForCode: "Szukaj kodu z tyłu opakowania",
    Verify: "Zweryfikuj",
    WrongRegistrationUrl: "Adres strony jest nieprawidłowy. Jeśli chcesz skorzystać z oferty wejdź na Ploom.pl lub zapytaj sprzedawcę.",
    Welcome: "Witaj",
    ZabkaRegisterDeviceViewMessage: "Wygląda na to, że już jesteś w bazie marketingowej JTI \r\ni masz konto na ploom.pl.\r\nJeszcze tylko jeden krok – wpisz numer swojego urządzenia.",
    Back: "Wróć",
    ConfirmPassword: "potwierdź hasło",
    EmailShort: "e-mail",
    Password: "hasło",
    ConfirmAdult: "Potwierdzam, że jestem pełnoletni",
    LegalPerson: "Prowadzę firmę (punkt sprzedaży)",
    PhysicalPerson: "Jestem pracownikiem",
    QRSalePoint: "kod QR punktu",
    RtNumber: "numer RT",
    ApartmentNumber: "Numer lokalu",
    City: "Miasto",
    Commune: "Gmina",
    CompanyApartmentNumber: "Adres siedziby numer lokalu",
    CompanyBuildingNumber: "Adres siedziby number budynku",
    CompanyCity: "Adres siedziby miasto",
    CompanyName: "Nazwa firmy",
    CompanyPostalCode: "Adres siedziby kod pocztowy",
    CompanyStreet: "Adres siedziby ulica",
    ConfirmRegulations: "Potwierdzam zapoznanie się z Regulaminem",
    Country: "Kraj",
    HomeNumber: "Numer domu",
    Nip: "NIP",
    PostalCode: "Kod pocztowy",
    PostOffice: "Poczta",
    Street: "Ulica",
    TaxOffice: "Urząd skarbowy",
    Voivodeship: "Województwo",
    RepeatEmail: "Powtórz e-mail",
    RestorePassword: "Odzyskaj hasło",
    SendLink: "Wyślij link",
    YourEmail: "Twój e-mail",
    SellOfferProcessInProgress: "Podany numer telefonu jest prawidowy i nie istnieje jeszcze w bazie. Poczekaj, aby dokoczy rejestracjé. Jeli nie chcesz czeka, moesz przerwa proces korzystajác z przycisku poniej.",
    RestorePasswordInfo: "Wysłaliśmy Ci e-mail z instrukcją jak zmienić hasło. Sprawdź swoją skrzynkę i stwórz nowe hasło.",
    EmailAlreadyExists: "Podany adres e-mail już istnieje w bazie sprzedawców.",
    ChooseAccountType: "Wybierz typ konta",
    ClerkRegistrationComplete: "Udało się pomyślnie zarejestrować konto. Przed zalogowaniem się należy potwierdzić je klikając w link który przyszedł na podany adres email.",
    GetPointOfSaleData: "Pobierz dane punktu",
    ChangePassword: "Zmiana hasła",
    ChangePasswordSuccessInfo: "Udało się pomyślnie zmienić hasło. Kliknij \"Wstecz\", aby cofnąć się do głównego ekranu.",
    EditProfile: "Edytuj profil",
    GatheredPoints: "Zebrane punkty",
    NewPassword: "Nowe hasło",
    OldPasswrod: "Stare hasło",
    Logout: "Wyloguj",
    Menu: "Menu",
    OfferPortfolio: "Portfolio ofert",
    OfferSale: "Sprzedaż oferty",
    Profile: "Profil",
    Pts: "pkt",
    Regulations: "Regulaminy",
    YourPoints: "Twoje punkty",
    InformationClause: "Klauzula informacyjna",
    OffersRegulations: "Regulaminy ofert",
    PlatformRegulations: "Regulamin platformy",
    AvfaliableOffers: "Dostępne oferty",
    ShowLess: "Pokaż mniej",
    ShowMore: "Pokaż więcej",
    Here: "tutaj",
    TheOrganizerOfThePromotionIsJtiPoland: "Organizatorem promocji jest JTI Polska.",
    YouCanFindRegulationLink: "Link do regulaminu znajdziesz",
    Regulation: "Regulamin",
    ScanOrEnterTheQrCodeOfThePointWhereYouAreCurrentlyWorking: "Zeskanuj lub wpisz kod QR punktu w którym aktualnie pracujesz",
    Pesel: "Pesel",
    PointOfSaleCode: "Kod sklepu",
    ProgramRegulations: "Regulamin Programu",
    AbortProcess: "Przerwij proces",
    Confirm: "Potwierdź",
    IConfirmTheSaleOfTheOffer: "Potwierdzam sprzedaż oferty",
    SaleFailedMessage: "Sprzedaż nie powiodła się.",
    SaleSuccessfullAdditionalWarning: "Pamiętaj, aby zachować paragon dla tej transakcji.\r\nPrzedstawiciel JTI poprosi Cię o jego udostępnienie.",
    SaleSuccessfulMessage: "Dziękujemy za sprzedaż naszej oferty. \r\nTeraz możesz wydać konsumentowi należne produkty.\r\nPo zapisaniu danych system zaktualizuje Twoją liczbę punktów.",
    ScanOrEnterTheQrCodeOfTheDevice: "Zeskanuj lub wpisz kod QR urządzenia",
    WaitForClientRegisterMessage: "Podany numer telefonu jest prawidłowy i nie istnieje jeszcze w bazie. Konsument dostanie za chwilę SMS z linkiem do rejestracji. Poczekaj, aż konsument zakończy rejestrację. Wtedy zostaniesz automatycznie przeniesiony do kolejnego widoku.",
    ProcessCanceledBySeller: "Proces został przerwany przez sprzedawcę.",
    CancelCustomerRegistrationProcessQuestion: "Czy na pewno chcesz przerwać proces rejestracji Konsumenta?",
    No: "Nie",
    ProvideSalePointCode: "Wprowadź kod punktu",
    Yes: "Tak",
    AccountConfirmedMessage: "Rejestracja konta zakończona powodzeniem.",
    AccountNotConfirmedMessage: "Rejestracja konta nie powiodła się.",
    ConfirmAccount: "Potwierdź konto",
    Install: "Zainstaluj",
    OfferSaleCancelation: "Jeżeli konsument zrezygnuje, możesz przerwać proces sprzedaży oferty używając przycisku poniżej.",
    ChooseOffer: "Proszę wybrać ofertę",
    CustomerNotYetRegistered: "Konsument nie zapisał jeszcze danych na formularzu lub minął czas na jego uzupełnienie.",
    GetData: "Pobierz dane",
    CustomerAlreadyRegistered: "Konsument jest zarejestrowany dokonaj sprzedaży oferty",
    Refresh: "Odśwież",
    AdministratorOfYourDataIsMessage: "Administratorem Twoich danych jest JTI Polska sp. z o.o.",
    ClickAndLearnMoreAboutDataProtection: "Kliknij i dowiedz się więcej o ochronie danych, w tym celach przetwarzania i o Twoich prawach.",
    Accept: "Akceptuję",
    InformationOnPersonalDataProtection: "informację o ochronie danych osobowych.",
    MakeSureYouHaveProvideTheCustomerWith: "Upewnij się, czy przekazałeś Klientowi",
    PrivacyPolicy: "Polityka prywatności",
    QuickApplicationGuide: "Skrócona instrukcja aplikacji",
    ProgramsForYou: "Programy dla Ciebie",
    ActualProgram: "Aktualny Program",
    ProgramArchive: "Archiwum programów",
    Counter: "Licznik",
    HideProgramRegulations: "Ukryj zasady programu",
    Reward: "Nagroda",
    SelectProgram: "Wybierz program",
    ShowProgramRegulations: "Pokaż zasady programu",
    NoActiveProgram: "Obecnie nie ma aktywnego programu",
    SelectOfferInfo: "Jeżeli oferty nie widać poniżej, oznacza to, że limit przypadający na Konsumenta został wyczerpany.",
};
