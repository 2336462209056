import { Info, InfoType } from "../../components/ui/Info/Info";
import styles from "./RegistrationConfirm.module.scss";
import langs from "../../common/services/languageService";
import Button from "../../components/ui/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { confirmRegistration } from "../../common/services/clerkService";

const { labels, validationMessages, templates } = langs;

export function RegistrationConfirm() {
  const navigate = useNavigate();
  let { email, code } = useParams();
  const [resultMessage, setResultMessage] = useState(<div></div>);

  useEffect(() => {
    confirmRegistration(email as string, code as string)
      .then((resp) => {
        setResultMessage(resp.data ? success : failed);
      })
      .catch(() => {});
  }, []);

  var success = (
    <Info
      hasIcon={true}
      type={InfoType.success}
      header={labels.ConfirmAccount()}
    >
      <div className={styles.registrationConfirm}>
        {labels.AccountConfirmedMessage()}
        <Button onClick={() => navigate("/login")}>{labels.LogIn().toLowerCase()}</Button>
      </div>
    </Info>
  );

  var failed = (
    <Info
      hasIcon={true}
      type={InfoType.warning}
      header={labels.ConfirmAccount()}
    >
      <div className={styles.registrationConfirm}>
        {labels.AccountNotConfirmedMessage()}
        <Button onClick={() => navigate("/register")}>{labels.Register().toLowerCase()}</Button>
      </div>
    </Info>
  );

  return <>{resultMessage}</>;
}
