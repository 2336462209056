import React, { memo } from 'react'
import Select, { SelectProps } from '../ui/Select/Select'
import { FieldValues, useController } from 'react-hook-form'
import { clsx } from 'clsx'
import ValidationLabel from '../ui/ValidationLabel/ValidationLabel'
import { formatFieldLabel } from '../../common/helpers/formHelper'

function SelectField({
  name,
  control,
  rules,
  ...props
}: SelectProps & FieldValues) {
  const { field, fieldState } = useController({
    name: name ? name : '',
    control,
    rules,
  })

  return (
    <div>
      <Select
        {...props}
        onChange={field?.onChange}
        hasError={!!fieldState.error}
        refs={field?.ref}
        value={field?.value}
        label={formatFieldLabel(props, rules)}
      />
      <ValidationLabel error={fieldState.error} />
    </div>
  )
}

export default memo(SelectField)
