import React, { memo, useEffect, useState } from 'react'
import ProgramsForYouUI from './ProgramsForYouUI'
import { useNavigate } from 'react-router'
import { LabelAndValue } from '../../components/ui/Select/OptionTypes'
import { ClerkRewardProgram } from '../../common/models/ClerkRewardProgram'
import {
  finishAsyncOperation,
  startAsyncOperation,
} from '../../common/commonSlice'
import { useDispatch } from 'react-redux'
import {
  getClerkRewardPrograms,
} from '../../common/services/clerkService'

interface ProgramsForYouProps {}

const ProgramsForYou: React.FC<ProgramsForYouProps> = ({}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [archivedPrograms, setArchivedPrograms] = useState<
    ClerkRewardProgram[] | undefined
  >()
  const [selectedArchivedProgram, setSelectedArchivedProgra] =
    useState<ClerkRewardProgram>()
  const [activeRewardProgram, setActiveRewardProgram] =
    useState<ClerkRewardProgram>()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    dispatch(startAsyncOperation())
    const allClerkRewardPrograms = (await getClerkRewardPrograms()).data

    const notActiveClerkRewardsProgram = allClerkRewardPrograms.filter(
      (x) => x?.isActive == false
    )
    const activeClerkRewardPrograms = allClerkRewardPrograms.find(
      (x) => x?.isActive
    )
    setArchivedPrograms(
      notActiveClerkRewardsProgram.map((x, index) => {
        return { ...x, id: index } as ClerkRewardProgram
      })
    )
    setActiveRewardProgram(activeClerkRewardPrograms)

    dispatch(finishAsyncOperation())
  }

  const _onArchiveProgramChange = (data: any) => {
    setSelectedArchivedProgra(data)
  }

  const _onGoBack = () => {
    navigate('/')
  }

  const isLoaded =
    activeRewardProgram != undefined || archivedPrograms != undefined

  return (
    <ProgramsForYouUI
      isLoaded={isLoaded}
      onGoBack={_onGoBack}
      onArchiveProgramChange={_onArchiveProgramChange}
      activeRewardProgram={activeRewardProgram}
      archivedPrograms={archivedPrograms}
      selectedArchivedProgram={selectedArchivedProgram}
    />
  )
}

export default memo(ProgramsForYou)
