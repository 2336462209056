import clsx from "clsx";
import React, { ChangeEvent, memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./RadioButton.module.scss";

export type RadioButtonProps = {
	value?: string | number | readonly string[] | undefined;
	id?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	label?: string | React.ReactElement;
	refs?: any;
	name: string;
	hasError?: boolean;
	checked?: boolean;
};

const RadioButton: React.FC<RadioButtonProps> = ({ value, id, onChange, disabled, label, name, hasError, refs, checked }) => {
	const onLocalChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange) onChange(event);
	};
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [valueState, setValueState] = useState(checked);

	useLayoutEffect(() => {
		if (checked !== undefined) setValueState(checked);
		else {
			setValueState(inputRef.current?.checked);
		}
	}, [checked, inputRef, refs]);

	return (
		<div className={clsx(styles.radioButtonContainer, valueState && styles.checked, disabled && styles.disabled, hasError && styles.validationError)}>
			<input
				className={styles.input}
				type="radio"
				id={id}
				value={value}
				onChange={!disabled ? onLocalChange : ()=>{}}
				name={name}
				checked={checked}
				disabled={disabled}
				ref={(e) => {
					inputRef.current = e;
					if (refs && typeof refs == "function") refs(e);
					else if (refs?.current) refs.current = e;
				}}
			/>
			<label className={styles.radioButton_item} htmlFor={`${id}`}>
				<span className={clsx(styles.radioButton)} />
				{label && <span className={styles.label}>{label}</span>}
			</label>
		</div>
	);
};

export default memo(RadioButton);
