import {
  emailRegex,
  passwordRegex,
  phoneNumberRegex,
} from "../../common/helpers/validationHelper";
import {
  finishAsyncOperation,
  showLoadingIndicatorSelector,
  startAsyncOperation,
} from "../../common/commonSlice";
import {
  RegisterOptions,
  Resolver,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import {
  getPointOfSale,
  getUserDictionary,
  registerClerk,
  validateEmail,
} from "../../common/services/clerkService";
import langs from "../../common/services/languageService";
import { formatPhoneNumber } from "../../common/helpers/formHelper";
import { parseInt } from "lodash";
import {
  selectCountries,
  selectTaxOffices,
  selectVoivodeships,
  setDictionary,
} from "../../common/dictionariesSlice";
import UserDictionaryTypes from "../../common/consts/UserDictionaryTypes";
import { ClerkTypes } from "../../common/consts/ClerkTypes";
import { RadioButtonListOption } from "../../components/form/RadioButtonList";
import { Info, InfoType } from "../../components/ui/Info/Info";
import Profile, { ProfileFormModes, ProfileValues } from "../Profile/Profile";
import { FieldModes } from "../../common/consts/FieldModes";
import { FieldsRegisterOptions } from "../../components/form/FieldType";

const { labels, validationMessages } = langs;

export enum registrationSteps {
  emailVerification,
  clerkRegistration,
  accountExists,
  clerkRegistrationSuccess,
}

const clerkTypeOptions = [
  {
    id: "individual",
    value: ClerkTypes.individual,
    label: labels.PhysicalPerson(),
  },
  {
    id: "legalEntity",
    value: ClerkTypes.legalEntity,
    label: labels.LegalPerson(),
  },
] as RadioButtonListOption[];

export function CreateProfile() {
  const validationRules: FieldsRegisterOptions<ProfileValues, RegisterOptions> =
    {
      firstName: {
        required: validationMessages.Required(),
        minLength: {
          value: 2,
          message: validationMessages.MinimumLength().replace("{0}", "2"),
        },
        maxLength: {
          value: 100,
          message: validationMessages.MaximumLength().replace("{0}", "100"),
        },
      },
      lastName: {
        required: validationMessages.Required(),
        minLength: {
          value: 2,
          message: validationMessages.MinimumLength().replace("{0}", "2"),
        },
        maxLength: {
          value: 100,
          message: validationMessages.MaximumLength().replace("{0}", "100"),
        },
      },
      email: {
        required: validationMessages.Required(),
        pattern: {
          value: emailRegex,
          message: validationMessages.Email(),
        },
        maxLength: {
          value: 200,
          message: validationMessages.MaximumLength().replace("{0}", "200"),
        },
      },
      password: {
        required: validationMessages.Required(),
        pattern: {
          value: passwordRegex,
          message: validationMessages.PasswordRequirements(),
        },
      },
      confirmPassword: {
        required: validationMessages.Required(),
        validate: (val: string) => {
          if (watch("password") != val) {
            return validationMessages.PasswordNotMatch();
          }
        },
      },
      phoneNumber: {
        required: validationMessages.Required(),
        pattern: {
          value: phoneNumberRegex,
          message: validationMessages.PhoneNumber(),
        },
      },
      rtNumber: {
        required: validationMessages.Required(),
        maxLength: {
          value: 100,
          message: validationMessages.MaximumLength().replace("{0}", "100"),
        },
      },
      qrCode: {
        required: validationMessages.Required(),
        maxLength: {
          value: 100,
          message: validationMessages.MaximumLength().replace("{0}", "100"),
        },
      },
      adultConfirmation: { required: validationMessages.Required() },
      confirmRegulations: { required: validationMessages.Required() },
      clerkTypeId: { required: validationMessages.Required() },
      captchaKey: {required: validationMessages.Required() }
    };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formStep, setFormStep] = useState(registrationSteps.emailVerification);
  const [submitButtonText, setSubmitButtonText] = useState(labels.Next());
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const countries = useAppSelector(selectCountries);
  const voivodeships = useAppSelector(selectVoivodeships);
  const taxOffices = useAppSelector(selectTaxOffices);

  const {
    control,
    formState: { errors, isValid, isSubmitted },
    handleSubmit,
    setValue,
    watch,
    getValues,
    setError,
    clearErrors,
  } = useForm<ProfileValues>({
    mode: "onChange",
    criteriaMode: "all",
  });

  useEffect(() => {
    init();
  }, []);

  const clerkTypeId = watch("clerkTypeId");

  const getSalePointData = (event: any) => {
    event.preventDefault();
    const rtNumber = getValues("rtNumber");

    if (!rtNumber) return;

    dispatch(startAsyncOperation());

    getPointOfSale(rtNumber)
      .then((resp) => {
        setValue("nip", resp.data.nip, { shouldValidate: true });
        setValue("companyName", resp.data.companyName, {
          shouldValidate: true,
        });
        dispatch(finishAsyncOperation());
      })
      .catch(() => {
        dispatch(finishAsyncOperation());
      });
  };

  const onQrScanned = (controlName: string, value: string) => {
    setValue(controlName as any, value, { shouldValidate: true });
  };

  const onGoBack = () => {
    navigate("/login");
  };

  const onSubmit: SubmitHandler<ProfileValues> = (data) => {
    switch (formStep) {
      case registrationSteps.emailVerification:
        dispatch(startAsyncOperation());
        validateEmail({ email: data.email, captchaKey: data.captchaKey })
          .then((resp) => {
            setValue("captchaKey", "");
            dispatch(finishAsyncOperation());

            if (resp.data.isAccountExists) {
              setFormStep(registrationSteps.accountExists);
            } else {
              setFormStep(registrationSteps.clerkRegistration);
              setSubmitButtonText(labels.Register().toLowerCase());
              setValue("email", data.email, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              });
            }
          })
          .catch((err) => {
            setValue("captchaKey", "");
            dispatch(finishAsyncOperation());
          });
        break;

      case registrationSteps.clerkRegistration:
        var payload: ProfileValues = {
          ...data,
          countryId: "10001",
          voivodeshipId: data.voivodeship?.id,
          taxOfficeId: data.taxOffice?.id,
          phoneNumber: formatPhoneNumber(data.phoneNumber),
          clerkTypeId: parseInt("" + data.clerkTypeId),
          captchaKey: data.captchaKey,
        };

        dispatch(startAsyncOperation());
        registerClerk(payload)
          .then((resp) => {
            setValue("captchaKey", "");

            if (resp.data.isClerkSuccessfullyRegistered) {
              setFormStep(registrationSteps.clerkRegistrationSuccess);
            }else if (!resp.data.pointOfSaleExists) {
              setError(
                "rtNumber",
                {
                  message: validationMessages.SalePointDoesntExist(),
                  type: "custom",
                },
                { shouldFocus: true }
              );
              setServerErrorMessage(
                validationMessages.SalePointDoesntExist()
              );
            } else if (!resp.data.isQrAndRTCodeValid) {
              setError(
                "rtNumber",
                {
                  message: validationMessages.SalePointAndRtNotMatch(),
                  type: "custom",
                },
                { shouldFocus: false }
              );
              setError(
                "qrCode",
                {
                  message: validationMessages.SalePointAndRtNotMatch(),
                  type: "custom",
                },
                { shouldFocus: true }
              );
              setServerErrorMessage(
                validationMessages.SalePointAndRtNotMatch()
              );
            } else {
              navigate("/error");
            }

            dispatch(finishAsyncOperation());
          })
          .catch((err) => {
            dispatch(finishAsyncOperation());
          });
        break;
    }
  };

  function init() {
    // Countries Dictionary
    dispatch(startAsyncOperation());
    getUserDictionary(UserDictionaryTypes.countries)
      .then((resp) => {
        dispatch(
          setDictionary({
            type: UserDictionaryTypes.countries,
            items: resp.data,
          })
        );
        dispatch(finishAsyncOperation());
      })
      .catch(() => {
        dispatch(finishAsyncOperation());
      });

    // Tax offices Dictionary
    dispatch(startAsyncOperation());
    getUserDictionary(UserDictionaryTypes.taxOffices)
      .then((resp) => {
        dispatch(
          setDictionary({
            type: UserDictionaryTypes.taxOffices,
            items: resp.data,
          })
        );
        dispatch(finishAsyncOperation());
      })
      .catch(() => {
        dispatch(finishAsyncOperation());
      });

    // Voivodeships Dictionary
    dispatch(startAsyncOperation());
    getUserDictionary(UserDictionaryTypes.voivodeships)
      .then((resp) => {
        dispatch(
          setDictionary({
            type: UserDictionaryTypes.voivodeships,
            items: resp.data,
          })
        );
        dispatch(finishAsyncOperation());
      })
      .catch(() => {
        dispatch(finishAsyncOperation());
      });
  }

  return (
    <>
      {formStep === registrationSteps.accountExists && (
        <Info
          hasIcon={true}
          type={InfoType.warning}
          header={labels.Register()}
          onGoBack={() => navigate(-1)}
        >
          <div style={{ padding: "0em 1em" }}>
            {labels.EmailAlreadyExists()}
          </div>
        </Info>
      )}
      {formStep === registrationSteps.clerkRegistrationSuccess && (
        <Info hasIcon={true} type={InfoType.success} header={labels.Register()}>
          <div style={{ padding: "0em 1em" }}>
            {labels.ClerkRegistrationComplete()}
          </div>
        </Info>
      )}
      {(formStep === registrationSteps.clerkRegistration ||
        formStep === registrationSteps.emailVerification) && (
        <Profile
          clerkTypeId={clerkTypeId}
          clerkTypeOptions={clerkTypeOptions}
          control={control}
          countries={countries}
          errors={errors}
          fieldMode={FieldModes.Create}
          formMode={ProfileFormModes.register}
          formStep={formStep}
          getSalePointData={getSalePointData}
          isValid={isValid}
          onGoBack={onGoBack}
          onQrScanned={onQrScanned}
          onSubmit={handleSubmit(onSubmit)}
          serverErrorMessage={serverErrorMessage}
          submitButtonText={submitButtonText}
          taxOffices={taxOffices}
          title={labels.Register()}
          validationRules={validationRules}
          voivodeships={voivodeships}
        />
      )}
    </>
  );
}
