import { useAppSelector } from '../../app/hooks'
import LoadingCounter from '../../components/ui/LoadingCounter/LoadingCounter'
import styles from './Layout.module.scss'
import { showLoadingIndicatorSelector } from '../../common/commonSlice'
import { signal } from '@preact/signals-react'
import { useBrowserDeviceDetector } from '../../common/hooks/useBrowserDeviceDetector'
import { useRef } from 'react'
import { Portal } from '../../components/ui/Portal/Portal'
import GitInfo from 'react-git-info/macro';
import DevelopVersionInfo from '../../components/ui/DevelopVersionInfo/DevelopVersionInfo'

type Props = {
  renderContent: () => JSX.Element
}


export const onLayoutScroll = signal((e: React.UIEvent<HTMLDivElement>) => {})

export function Layout({ renderContent }: Props) {
  useBrowserDeviceDetector()
  const gitInfo = GitInfo();
  const isLoading = useAppSelector(showLoadingIndicatorSelector)
  const bodyRef = useRef<HTMLDivElement>(null)

  return (
    <div className={styles.layout}>
      {isLoading && <LoadingCounter />}
      <div
        className={styles.body}
        ref={bodyRef}
        onScroll={onLayoutScroll?.value}
      >
        {renderContent()}
      </div>
    </div>
  )
}
