import Content from '../../components/ui/Content/Content'
import { SvgIcon } from '../../components/ui/SvgIcon/SvgIcon'
import styles from './AvaliableOffers.module.scss'
import { useNavigate } from 'react-router'
import { getOffers } from '../../common/services/clerkService'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../app/hooks'
import { OfferCard } from '../../components/ui/OfferCard/OfferCard'
import langs from '../../common/services/languageService'
import {
  finishAsyncOperation,
  startAsyncOperation,
} from '../../common/commonSlice'
import AnimationBlock from '../../components/ui/AnimationBlock/AnimationBlock'
import { Offer } from '../../common/models/Offer'

const { labels } = langs

export function AvaliableOffers() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [offers, setOffers] = useState<Offer[]>()
  const onGoBack = () => {
    navigate('/')
  }
  useEffect(() => {
    dispatch(startAsyncOperation())
    getOffers()
      .then((resp: any) => {
        dispatch(finishAsyncOperation())
        setOffers(resp.data)
      })
      .catch(() => {
        dispatch(finishAsyncOperation())
      })
  }, [])

  const isLoaded = offers != undefined

  return (
    <>
      {isLoaded && (
        <Content
          icon={<SvgIcon icon={'user'} />}
          title={labels.AvfaliableOffers()}
          goBackLabel={labels.Back().toLowerCase()}
          onGoBack={onGoBack}
        >
          <div>
            {offers?.length > 0 && (
              <div className={styles.avaliableOffers}>
                {offers.map((offer: Offer, index: number) => (
                  <AnimationBlock
                    key={offer.offerId}
                    animationDelay={`${index * 0.1}s`}
                  >
                    <OfferCard
                      offer={offer}
                      showDescription
                    />
                  </AnimationBlock>
                ))}
              </div>
            )}
          </div>
        </Content>
      )}
    </>
  )
}
