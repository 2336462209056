import React, { ReactElement, memo, useEffect, useMemo, useState } from "react";
import styles from "./Content.module.scss";
import Button from "../Button/Button";
import clsx from "clsx";
import { footerExist } from "../Footer/Footer";
import { SvgIcon } from "../SvgIcon/SvgIcon";

interface ContentProps {
	icon?: ReactElement;
	title?: string | ReactElement;
	children?: (boolean | ReactElement )[] | ReactElement | boolean;
	buttons?: ReactElement; 
	onGoBack?: () => void;
	goBackLabel?: string;
	additionalHeaderElements?: string | ReactElement | false;
	className?: string;
}

const Content: React.FC<ContentProps> = ({ icon, additionalHeaderElements, title, children, buttons, onGoBack, goBackLabel = "wróć", className }) => {
	const shouldRenderButtonPanel = useMemo(() => {
		return onGoBack || buttons;
	}, [onGoBack, buttons]);

	const [iconWithDefaultHeight, setIconWithDefaultHeight] = useState(false);

	useEffect(() => {
		if (icon && (icon?.type as any)?.name == "SvgIcon" && !icon.props.height) {
			setIconWithDefaultHeight(true);
		} else setIconWithDefaultHeight(false);
	}, [icon]);

	return (
		<div className={clsx(styles.content,className && className)}>
			<div className={styles.header}>
				<div className={styles.icon}>{iconWithDefaultHeight ? React.cloneElement(icon as ReactElement, { height: "var(--contentIconSize)"}) : icon}</div>
				<h1 className={styles.title}>{title}</h1>
				{additionalHeaderElements && <div className={styles.additionalHeaderElements}>{additionalHeaderElements}</div>}
			</div>

			{shouldRenderButtonPanel && (
				<div className={styles.buttonPanel}>
					{onGoBack && (
						<Button icon="circleSimpleArrowLeft" onClick={onGoBack} animation>
							{goBackLabel.toLowerCase()}
						</Button>
					)}
					{buttons}
				</div>
			)}
			<div className={clsx(styles.body, footerExist.value && styles.withAdditionalPadding)}>{children}</div>
		</div>
	);
};

export default memo(Content);
