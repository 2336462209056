import React, { memo, useEffect } from "react";
import { FieldValues, useController } from "react-hook-form";
import RadioButton, { RadioButtonProps } from "../ui/RadioButton/RadioButton";

function RadioButtonField({ name, control, rules, ...props }: FieldValues & RadioButtonProps) {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
	});

	return <RadioButton name={name} value={props.value} refs={field?.ref} onChange={field?.onChange} hasError={!!fieldState.error} {...props} checked={field.value == props.value} />;
}

export default memo(RadioButtonField);
