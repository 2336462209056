import { SvgIcon } from '../../components/ui/SvgIcon/SvgIcon'
import Content from '../../components/ui/Content/Content'
import styles from './Profile.module.scss'
import { RadioButtonListOption } from '../../components/form/RadioButtonList'
import { registrationSteps } from '../CreateProfile/CreateProfile'
import { Control, FieldErrors, RegisterOptions } from 'react-hook-form'
import { FieldModes } from '../../common/consts/FieldModes'
import clsx from 'clsx'
import { Portal } from '../../components/ui/Portal/Portal'
import ploom_device from '../../styles/images/ploom_device.png'
import Image from '../../components/ui/Image/Image'
import { FieldsRegisterOptions } from '../../components/form/FieldType'
import EmailVerificationStep from './Steps/EmailVerificationStep'
import FormLayout from '../../components/ui/FormLayout/FormLayout'
import langs from '../../common/services/languageService'
import ClerkRegistrationStep from './Steps/ClerkRegistrationStep'

const { labels } = langs

export enum ProfileFormModes {
  profile,
  register,
}

export type ProfileValues = {
  adultConfirmation: boolean;
  buildingNumber: string;
  city: string;
  clerkTypeId: number;
  companyName: string;
  confirmPassword: string;
  confirmRegulations: boolean;
  country: RadioButtonListOption;
  countryId?: string;
  email: string;
  firstName: string;
  lastName: string;
  nip: string;
  password: string;
  phoneNumber: string;
  premisesNumber: string;
  qrCode: string;
  rtNumber: string;
  taxOffice: RadioButtonListOption;
  taxOfficeId?: string;
  voivodeship: RadioButtonListOption;
  voivodeshipId?: string;
  captchaKey?: string;

}

interface ProfileProps {
  clerkTypeId: number;
  clerkTypeOptions: RadioButtonListOption[];
  companyEditDisabled?: boolean;
  control: Control<any>;
  countries: [];
  errors: FieldErrors<ProfileValues>;
  fieldMode: FieldModes;
  formMode: ProfileFormModes;
  formStep: registrationSteps;
  getSalePointData: (e: any) => void;
  isValid: boolean;
  onSetEdit?: () => void;
  onGoBack?: () => void;
  onQrScanned: (controlName: string, value: string) => void;
  onSubmit?: () => void;
  serverErrorMessage?: string;
  submitButtonText: string;
  taxOffices: [];
  title: string;
  validationRules: FieldsRegisterOptions<ProfileValues, RegisterOptions>;
  voivodeships: [];
  recaptchaRef?: any;
  onReCaptchaChange?: (key: string | null) => void;
}

const Profile: React.FC<ProfileProps> = ({
  clerkTypeId,
  clerkTypeOptions,
  companyEditDisabled,
  control,
  countries,
  errors,
  fieldMode,
  formMode,
  formStep,
  getSalePointData,
  onSetEdit,
  onGoBack,
  onQrScanned,
  onSubmit,
  serverErrorMessage,
  submitButtonText,
  taxOffices,
  title,
  validationRules,
  voivodeships,
  recaptchaRef,
}) => {
  return (
    <Content
      className={styles.register}
      icon={<SvgIcon icon={'user'} />}
      title={title}
      goBackLabel={labels.Back().toLowerCase()}
      onGoBack={onGoBack}
    >
      <div
        className={clsx(
          styles.register_container,
          formStep == registrationSteps.emailVerification && styles.center
        )}
      >
        <FormLayout
          onSubmit={onSubmit}
          className={clsx(
            styles.register_form,
            fieldMode == FieldModes.Read && styles.readOnly
          )}
        >
          {formStep == registrationSteps.emailVerification && (
            <EmailVerificationStep
              control={control}
              validationRules={validationRules}
            />
          )}
          {formStep === registrationSteps.clerkRegistration && (
            <ClerkRegistrationStep
              control={control}
              clerkTypeId={clerkTypeId}
              clerkTypeOptions={clerkTypeOptions}
              countries={countries}
              fieldMode={fieldMode}
              formMode={formMode}
              getSalePointData={getSalePointData}
              onQrScanned={onQrScanned}
              submitButtonText={submitButtonText}
              taxOffices={taxOffices}
              validationRules={validationRules}
              voivodeships={voivodeships}
              companyEditDisabled={companyEditDisabled}
              onSetEdit={onSetEdit}
              serverErrorMessage={serverErrorMessage}
              recaptchaRef={recaptchaRef}
              errors={errors}
            />
          )}
        </FormLayout>
      </div>

      {formStep === registrationSteps.emailVerification && (
        <Portal>
          <Image
            src={ploom_device}
            className={styles.img}
          />
        </Portal>
      )}
    </Content>
  )
}

export default Profile
