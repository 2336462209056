import React, { ReactElement, memo } from "react";
import { SvgIcon } from "../../../components/ui/SvgIcon/SvgIcon";
import styles from ".././Profile.module.scss";
import { clsx } from "clsx";
import { FieldModes } from "../../../common/consts/FieldModes";
import langs from "../../../common/services/languageService";
import TextBoxField from "../../../components/form/TextBoxField";
import { FieldsRegisterOptions } from "../../../components/form/FieldType";
import { ProfileValues } from "../Profile";
import { RegisterOptions } from "react-hook-form";
import AnimationBlock from "../../../components/ui/AnimationBlock/AnimationBlock";
import Button from "../../../components/ui/Button/Button";
import Row, { RowProps } from "../../../components/ui/FormLayout/Row";
import Col from "../../../components/ui/FormLayout/Col";
import Link from "../../../components/ui/Link/Link";
import { getRegulationLink } from "../../../common/services/clerkService";
import { RegulationsTypes } from "../../../common/consts/RegulationsTypes";
import ReCaptchaField from "../../../components/form/ReCaptchaField";
const { labels } = langs;

interface EmailVerificationStepProps {
	validationRules: FieldsRegisterOptions<ProfileValues, RegisterOptions>;
	control: any;
}

const EmailVerificationStep: React.FC<EmailVerificationStepProps> = ({ validationRules, control }) : ReactElement<RowProps> => {
	return (
		<Row>
			<Col>
				<SvgIcon className={styles.ploomLogo} icon="logo" customColor="#fff" animation={true} animationDelay={300} />
				<AnimationBlock animation="fade-in" animationDelay="0.2s">
					<TextBoxField name="email" control={control} rules={validationRules.email} inputProps={{ inputMode: "email" }} label={labels.EmailShort().toLowerCase()} />
				</AnimationBlock>

				<AnimationBlock animation="fade-in" animationDelay="0.3s">
					<div className={styles.additionalText}>
						<span>{labels.AdministratorOfYourDataIsMessage()}</span>
						<Link download href={getRegulationLink({type: RegulationsTypes.privacyPolicy, offerId: null })}>{labels.ClickAndLearnMoreAboutDataProtection()}</Link>
					</div>
				</AnimationBlock>

				<AnimationBlock animation="fade-in" animationDelay="0.4s">
					<div className={styles.buttons}>
						<Button buttonType="primary" size="md" type={"submit"}>
							{labels.Next()}
						</Button>
					</div>
				</AnimationBlock>
				<AnimationBlock animation="fade-in" animationDelay="0.6s">
					<ReCaptchaField control={control} name="captchaKey" className={styles.recaptcha} />
				</AnimationBlock>
			</Col>
		</Row>
	);
};

export default memo(EmailVerificationStep);
