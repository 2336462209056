import React, { MouseEventHandler, memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { icons, IconTypes } from "./Icons/icons";
import clsx from "clsx";
import styles from "./SvgIcon.module.scss";

export interface SvgIconProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
	animation?: boolean;
	height?: string;
	icon: IconTypes;
	className?: string;
	onClick?: MouseEventHandler<HTMLDivElement>;
	customColor?: string;
	accentColor?: string;
	aspecRatio?: string;
	animationDelay?: number;
	crossed?: boolean;
}

export const SvgIcon = ({ accentColor, icon, className, customColor, height, animation = false, animationDelay = 0, aspecRatio, onClick, crossed, ...props }: SvgIconProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [playAnimation, setPlayAnimation] = useState(false);
	const [hide, setHide] = useState(animation);

	useLayoutEffect(() => {
		if (animation) {
			setTimeout(() => {
				setPlayAnimation(false);
				setHide(true);
			}, animationDelay);
			setTimeout(() => {
				setPlayAnimation(true);
				setHide(false);
			}, animationDelay + 20);
		}
	}, [icon, animation, animationDelay]);

	useLayoutEffect(() => {
		if (!icon || icons[icon] === undefined) console.warn(`Icon "${icon}" not found`);
	}, [icon]);

	useEffect(() => {
		if (customColor && customColor !== "" && ref?.current) ref.current.style.setProperty("--iconColor", customColor);
	}, [customColor]);

	useEffect(() => {
		if (accentColor && accentColor !== "" && ref?.current) ref.current.style.setProperty("--iconAccentColor", accentColor);
	}, [accentColor]);

	const heightStyle = height ? { height: height } : null;
	const cursorStyle = onClick ? { cursor: "pointer" } : null;

	return (
		<div ref={ref} onClick={onClick} style={{ visibility: hide ? "hidden" : "visible", aspectRatio: aspecRatio, ...heightStyle, ...cursorStyle } as React.CSSProperties} className={clsx(styles.icon, className, crossed && styles.crossed, animation && playAnimation && styles.animation)} {...props}>
			{icons[icon]()}
		</div>
	);
};
