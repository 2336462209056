import Button from "../../components/ui/Button/Button";
import Content from "../../components/ui/Content/Content";
import HamburgerMenu from "../../components/ui/Menu/HamburgerMenu/HamburgerMenu";
import { SvgIcon } from "../../components/ui/SvgIcon/SvgIcon";
import styles from "./Home.module.scss";
import Menu from "./Menu";
import { ClerkBalanceDataType } from "../../common/dataTypes/ClerkType";
import { Portal } from "../../components/ui/Portal/Portal";
import ploom_device from "../../styles/images/ploom_device.png";
import Image from "../../components/ui/Image/Image";
import langs from "../../common/services/languageService";
import { Footer } from "../../components/ui/Footer/Footer";
import { isMobile, isPC } from "../../common/hooks/useBrowserDeviceDetector";
import { IconTypes } from "../../components/ui/SvgIcon/Icons/icons";
import AnimationBlock from "../../components/ui/AnimationBlock/AnimationBlock";
import { IBeforeInstallPromptEvent } from "../../common/hooks/useAddToHomescreenPrompt";
import { permissionsSelector } from "../../common/commonSlice";
import { useAppSelector } from "../../app/hooks";
import permissions from "../../common/consts/Permissions";

const { labels } = langs;

interface HomeUiProps {
  user?: ClerkBalanceDataType;
  onMenuItemClick?: React.MouseEventHandler<HTMLButtonElement>;
  onLogOut: () => void;
  prompt: IBeforeInstallPromptEvent | null;
  promptToInstall: () => void;
}

const HomeUI: React.FC<HomeUiProps> = ({ user, onMenuItemClick, onLogOut, prompt, promptToInstall }) => {
  const userPermissions = useAppSelector(permissionsSelector);

  const getMobileMenuButtons = () => (
    <div className={styles.buttons}>
      <AnimationBlock animation="fade-in-top" animationDuration="0.5s">
        <Button id="offerPortfolioBtn" onClick={onMenuItemClick} buttonType="secondary">
          {labels.OfferPortfolio().toLowerCaseFirstLetter()}
        </Button>
      </AnimationBlock>
      <AnimationBlock animation="fade-in-top" animationDelay="0.1s" animationDuration="0.5s">
        <Button id="offerSaleBtn" onClick={onMenuItemClick} buttonType="secondary">
          {labels.OfferSale().toLowerCaseFirstLetter()}
        </Button>
      </AnimationBlock>
      {userPermissions?.find((p) => p === permissions.RewardProgramAccess) && (
        <AnimationBlock animation="fade-in-top" animationDelay="0.2s" animationDuration="0.5s">
          <Button id="programsForYouBtn" onClick={onMenuItemClick} buttonType="secondary">
            {labels.ProgramsForYou().toLowerCaseFirstLetter()}
          </Button>
        </AnimationBlock>
      )}
      <AnimationBlock animation="fade-in-top" animationDelay="0.3s" animationDuration="0.5s">
        <Button id="regulationsBtn" onClick={onMenuItemClick} buttonType="secondary">
          {labels.Regulations().toLowerCaseFirstLetter()}
        </Button>
      </AnimationBlock>
    </div>
  );

  const getTileButton = ({
    id,
    onClick,
    icon,
    label,
    animationDelay,
  }: {
    id: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    icon: IconTypes;
    label: string;
    animationDelay?: string;
  }) => {
    return (
      <AnimationBlock animation="fade-in-left" animationDelay={animationDelay}>
        <Button id={id} onClick={onClick} buttonType="secondary" className={styles.tileButton}>
          <SvgIcon icon={icon} className={styles.tileButton_icon} />
          <>{label}</>
        </Button>
      </AnimationBlock>
    );
  };
  const getPcMenuButtons = () => (
    <div className={styles.pcButtons}>
      {getTileButton({
        id: "offerPortfolioBtn",
        onClick: onMenuItemClick,
        icon: "percent",
        label: labels.OfferPortfolio(),
      })}
      {getTileButton({
        id: "offerSaleBtn",
        onClick: onMenuItemClick,
        icon: "circleCheck",
        label: labels.OfferSale(),
        animationDelay: "0.2s",
      })}
      {userPermissions?.find((p) => p === permissions.RewardProgramAccess)
        ? getTileButton({
            id: "programsForYouBtn",
            onClick: onMenuItemClick,
            icon: "star",
            label: labels.ProgramsForYou(),
            animationDelay: "0.4s",
          })
        : ""}
      {getTileButton({
        id: "regulationsBtn",
        onClick: onMenuItemClick,
        icon: "regulations",
        label: labels.Regulations(),
        animationDelay: "0.4s",
      })}
    </div>
  );

  return (
    <Content
      className={styles.mainContent}
      icon={<SvgIcon icon={"star"} animation height="40px" />}
      title={
        <div className={styles.points_section}>
          <span className={styles.ponints_label}>{labels.YourPoints()}:</span>
          <span className={styles.ponints_value}>
            {user?.bonusPointBalance ?? 0}
            <span className={styles.points_mark}>{labels.Pts()}</span>
          </span>
        </div>
      }
      additionalHeaderElements={
        isMobile() && (
          <HamburgerMenu>
            <Menu prompt={prompt} promptToInstall={promptToInstall} user={user} onLogOut={onLogOut} />
          </HamburgerMenu>
        )
      }>
      <div>
        <div className={styles.home}>
          <AnimationBlock>
            <h1>{labels.Menu()}</h1>
          </AnimationBlock>
          {isMobile() ? getMobileMenuButtons() : getPcMenuButtons()}
        </div>
        {isPC() && (
          <AnimationBlock animation="fade-in-right" slideSize="100px">
            <Menu
              prompt={prompt}
              promptToInstall={promptToInstall}
              user={user}
              onLogOut={onLogOut}
              className={styles.pcMenu}
            />
          </AnimationBlock>
        )}
      </div>
      <Footer />
      <Portal>
        <Image src={ploom_device} className={styles.img} />
      </Portal>
    </Content>
  );
};

export default HomeUI;
