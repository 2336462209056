import React, { memo, useState } from 'react'
import { SvgIcon } from '../SvgIcon/SvgIcon'
import TextBox, { TextBoxProps } from '../TextBox/TextBox'
import { FieldValues, useController } from 'react-hook-form'
import ValidationLabel from '../ValidationLabel/ValidationLabel'
import { clsx } from 'clsx'
import { formatFieldLabel } from '../../../common/helpers/formHelper'
import { isPropertyName } from 'typescript'
import { type } from 'os'

const passwordTypes = ['current-password', 'new-password'] as const

type PasswordType = (typeof passwordTypes)[number]

type PasswordInputProps = {
  passwordType?: PasswordType
}

function PasswordInput({
  passwordType = 'new-password',
  name,
  control,
  rules,
  ...props
}: PasswordInputProps & FieldValues & TextBoxProps) {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  })
  const [inputType, setInputType] = useState<'text' | 'password'>('password')

  const onTogglePasswordPreview = () => {
    setInputType(inputType === 'password' ? 'text' : 'password')
  }

  return (
    <div>
      <TextBox
        id={name}
        value={field?.value}
        refs={field?.ref}
        onChange={field?.onChange}
        hasError={!!fieldState.error}
        inputProps={{ type: inputType, autoComplete: passwordType }}
        additionalElement={
          <SvgIcon
            icon="eye"
            onClick={onTogglePasswordPreview}
            crossed={inputType !== 'password'}
          />
        }
        {...props}
        label={formatFieldLabel(props, rules)}
      />
      <ValidationLabel error={fieldState.error} />
    </div>
  )
}

export default memo(PasswordInput)
