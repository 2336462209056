import { ChangePassword } from "./features/ChangePassword/ChangePassword";
import { ForgotPassword } from "./features/ForgotPassword/ForgotPassword";
import { InfoPage } from "./features/Info/InfoPage";
import { Layout } from "./features/Layout/layout";
import { LoginPage } from "./features/Login/LoginPage";
import { NotFound } from "./features/NotFound/NotFound";
import { Outlet, Route, Routes } from "react-router";
import { CreateProfile } from "./features/CreateProfile/CreateProfile";
import { ResetPassword } from "./features/ResetPassword/ResetPassword";
import GuardedRoute from "./components/GuardedRoute/GuardedRoute";
import { EditProfile } from "./features/EditProfile/EditProfile";
import { Regulations } from "./features/Regulations/Regulations";
import { AvaliableOffers } from "./features/AvaliableOffers/AvaliableOffers";
import OfferSale from "./features/OfferSale/OfferSale";
import { RegistrationConfirm } from "./features/RegistrationConfirm/RegistrationConfirm";
import { useAddToHomescreenPrompt } from "./common/hooks/useAddToHomescreenPrompt";
import Home from "./features/Home/Home";
import ProgramsForYou from "./features/ProgramsForYou/ProgramsForYou";
import permissions from "./common/consts/Permissions";

function App() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  return (
    <Routes>
      <Route path="/" element={<Layout renderContent={() => <Outlet />} />}>
        <Route index element={<GuardedRoute element={<Home prompt={prompt} promptToInstall={promptToInstall} />} />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<CreateProfile />} />
        <Route path="/info" element={<InfoPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<GuardedRoute element={<ChangePassword />} />} />
        <Route path="/profile" element={<GuardedRoute element={<EditProfile />} />} />
        <Route path="/regulations" element={<GuardedRoute element={<Regulations />} />} />
        <Route path="/reset-password/:email/:code" element={<ResetPassword />} />
        <Route path="/avaliable-offers" element={<GuardedRoute element={<AvaliableOffers />} />} />
        <Route path="/registration-confirm/:email/:code" element={<RegistrationConfirm />} />
        <Route path="/offerSale" element={<GuardedRoute element={<OfferSale />} />} />
        <Route
          path="/programsForYou"
          element={<GuardedRoute element={<ProgramsForYou />} requiredPermission={permissions.RewardProgramAccess} />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
