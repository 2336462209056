import React, { memo } from 'react'
import styles from './LabelWithValue.module.scss';
import clsx from 'clsx';

interface LabelWithValueProps {
  className?: string
  label?: string | React.ReactElement;
  value?: any;
  valueAccentColor?: boolean;
}

const LabelWithValue: React.FC<LabelWithValueProps> = ({label, value, className, valueAccentColor}) => {
  return <div className={clsx(styles.container, className)}>
    {label && <label>{label}:</label>}
    <span className={clsx(valueAccentColor && styles.valueAccentColor)}>{value ?? "-"}</span>
  </div>
}

export default memo(LabelWithValue)
