import { signal } from '@preact/signals-react'
import React, { memo, useEffect } from 'react'

export enum BrowserDeviceTypes {
  PC = 0,
  Tablet = 1,
  Mobile = 3,
}

export const browserDeviceType = signal(BrowserDeviceTypes.PC)
export const clientWidth = signal(window.screen.width)

export const isPC = () => browserDeviceType?.value == BrowserDeviceTypes.PC
export const isMobile = () =>
  browserDeviceType?.value == BrowserDeviceTypes.Mobile

/**
 *Detecting browser device and put result to the browserDeviceType as signal (@preact/signals-react)\
 *
 *Declare only once per application in root component.
 **/
export const useBrowserDeviceDetector = () => {
  const detectResize = () => {
	clientWidth.value = document.body.clientWidth;
  }

  const detectBrowser = () => {
    const _userAgent = navigator?.userAgent
    const hasTouchPad = navigator?.maxTouchPoints > 0

    if ((window as any).config.forceMobileView)
      browserDeviceType.value = BrowserDeviceTypes.Mobile
    else if (
      hasTouchPad ||
      _userAgent?.includes('Android') ||
      _userAgent?.includes('iPhone')
    )
      browserDeviceType.value = BrowserDeviceTypes.Mobile
    else if (_userAgent?.includes('Windows') || _userAgent?.includes('Mac'))
      browserDeviceType.value = BrowserDeviceTypes.PC
  }

  useEffect(() => {
    detectBrowser()
    document.body.className =
      BrowserDeviceTypes[browserDeviceType.value].toLowerCase()
    window.addEventListener('resize', detectResize)
    return () => {
      window.removeEventListener('resize', detectResize)
    }
  }, [])

  return [detectBrowser] as const
}
