import React, { memo } from 'react'
import TextBox, { TextBoxProps } from '../ui/TextBox/TextBox'
import { useController, FieldValues } from 'react-hook-form'
import ValidationLabel from '../ui/ValidationLabel/ValidationLabel'
import { clsx } from 'clsx'
import { formatFieldLabel } from '../../common/helpers/formHelper'

function TextBoxField({
  name,
  control,
  rules,
  refs,
  id,
  inputProps,
  ...props
}: FieldValues & TextBoxProps & { refs?: React.MutableRefObject<any>  }) {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  })

  return (
    <div className={clsx(props.className)}>
      <TextBox
        id={name}
        {...props}
        inputProps={{...inputProps, autoComplete: inputProps?.autoComplete ?? "off"}}
        value={field?.value}
        refs={(e: any) => {
          field.ref(e);
          if (refs) refs.current = e;
        }}
        onChange={field?.onChange}
        hasError={!!fieldState.error}
        label={formatFieldLabel(props, rules)}
        className="" //pass className only to the container
      />
      <ValidationLabel error={fieldState.error} />
    </div>
  )
}

export default memo(TextBoxField)
