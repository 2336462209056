import { useNavigate } from 'react-router-dom'
import HomeUI from './HomeUI'
import { ClerkBalanceDataType } from '../../common/dataTypes/ClerkType'
import { useEffect, useState } from 'react'
import { getClerkBalanceData } from '../../common/services/clerkService'
import { useAppSelector } from '../../app/hooks'
import { IBeforeInstallPromptEvent } from '../../common/hooks/useAddToHomescreenPrompt'

interface HomeProps {
  prompt: IBeforeInstallPromptEvent | null
  promptToInstall: () => void
}

const Home: React.FC<HomeProps> = ({ prompt, promptToInstall }) => {
  const [clerkData, setClerkData] = useState<ClerkBalanceDataType>({})
  const clerkId = parseInt(localStorage.getItem('userId')!)

  const navigate = useNavigate()

  const _onMenuItemClick = (e: React.MouseEvent<any>) => {
    switch (e.currentTarget.id) {
      case 'offerPortfolioBtn':
        navigate('/avaliable-offers')
        break
      case 'offerSaleBtn':
        navigate('/offerSale')
        break
      case 'regulationsBtn':
        navigate('/regulations')
        break
      case 'programsForYouBtn':
        navigate('/programsForYou')
        break
    }
  }

  const _onLogOut = () => {
    localStorage.clear()
    navigate('/login')
  }

  useEffect(() => {
      getClerkBalanceData().then((result) => {
        setClerkData(result.data)
      })
  }, [])

  return (
    <HomeUI
      prompt={prompt}
      promptToInstall={promptToInstall}
      user={clerkData}
      onLogOut={_onLogOut}
      onMenuItemClick={_onMenuItemClick}
    />
  )
}

export default Home
