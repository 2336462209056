import styles from "./Footer.module.scss";
import langs from "../../../common/services/languageService";
import Link from "../../../components/ui/Link/Link";
import { getRegulationLink } from "../../../common/services/clerkService";
import { RegulationsTypes } from "../../../common/consts/RegulationsTypes";
import { useEffect, useRef } from "react";
import { signal } from "@preact/signals-react";

export const footerExist = signal(false);
const { labels } = langs;

export function Footer() {
	const footerRef = useRef<HTMLDivElement>(null);
	const footerContentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// if(footerContentRef.current && footerRef.current) {
		// 	const footerContentRect = footerContentRef.current.getBoundingClientRect();
		// 	footerContentRef.current.style.setProperty("margin-bottom",`-${footerContentRect.height}px`);
		// 	footerRef.current.style.setProperty("max-height",`${footerContentRect.height}px`);
		// }
	}, [footerContentRef.current ]);
	return (
		<footer className={styles.footer} ref={footerRef}>
			<div className={styles.footer_content} ref={footerContentRef}>
				<div>{labels.TheOrganizerOfThePromotionIsJtiPoland()}</div>
				<div>
					<span>{labels.YouCanFindRegulationLink()}</span>
					<Link
						download
						href={getRegulationLink({
							type: RegulationsTypes.platform,
							offerId: null,
						})}
					>
						{labels.Here()}
					</Link>
				</div>
			</div>
		</footer>
	);
}
