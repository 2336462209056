import Content from "../Content/Content";
import { SvgIcon } from "../SvgIcon/SvgIcon";
import langs from "../../../common/services/languageService";
import styles from "./Info.module.scss";
import { IconTypes } from "../SvgIcon/Icons/icons";
import clsx from "clsx";
import { ReactElement } from "react";

const { labels, validationMessages } = langs;

export enum InfoType {
  info,
  warning,
  success,
}

type InfoProps = {
  header: string;
  type: InfoType;
  hasIcon: boolean;
  children?: ReactElement | ReactElement[];
  buttons?: ReactElement | ReactElement[];
  onGoBack?: () => void;
};

export function Info({
  children,
  buttons,
  header,
  type,
  hasIcon,
  onGoBack,
}: InfoProps) {
  let icon: IconTypes = "check";
  let iconClass = "";
  let iconColorVariable = "infoColor"
  
  switch (type) {
    case InfoType.success:
      icon = "circleCheck";
      iconClass = styles.successIcon;
      break;
    case InfoType.warning:
      icon = "circleXMark";
      iconClass = styles.warningIcon;
      break;
    default:
      icon = "check";
      iconClass = styles.info;
      break;
  }
  return (
    <Content
      icon={<SvgIcon icon={"user"} />}
      title={header}
      goBackLabel={labels.Back().toLowerCase()}
      onGoBack={onGoBack}
    >
      <div className={styles.infoContainer}>
        {hasIcon && (
          <SvgIcon
            className={clsx(iconClass, styles.iconBase)}
            icon={icon}
            height="150px"
            animation
          />
        )}
        <div className={styles.body}>{children}</div>
        {buttons && <div className={styles.buttons}>{buttons}</div>}
      </div>
    </Content>
  );
}
