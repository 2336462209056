import React, { FormEventHandler, NamedExoticComponent, ReactElement, memo } from "react";
import styles from "./FormLayout.module.scss";
import { clsx } from "clsx";
import { RowProps } from "./Row";

interface FormLayoutProps {
	children?: (false | ReactElement<RowProps>)[] | boolean | ReactElement<RowProps>;
	className?: string;
	onSubmit?: FormEventHandler<HTMLFormElement>;
}

const FormLayout: React.FC<FormLayoutProps> = ({ children, className, onSubmit }) => {
	return (
		<form 
            onSubmit={onSubmit} 
            className={clsx(styles.form, className)}
        >
			{children}
		</form>
	);
};

export default memo(FormLayout);
