import React, { memo, useState } from "react";
import { Control } from "react-hook-form";
import { OfferSaleType } from "../../../common/dataTypes/OfferSaleType";
import TextBoxWithQrScanner from "../../../components/ui/TextBoxWithQrScanner/TextBoxWithQrScanner";
import Button from "../../../components/ui/Button/Button";
import styles from "../OfferSale.module.scss";
import langs from "../../../common/services/languageService";
const {labels, validationMessages} = langs;

interface EnterSalePointProps {
	control: Control<OfferSaleType, any>;
	onQrScanned: (controlName: string, value: string, submitAfterScan?: boolean) => void;
	onSubmit: () => void;
}

const EnterSalePoint: React.FC<EnterSalePointProps> = ({ control, onQrScanned, onSubmit }) => {
	return (
		<>
			<div className={styles.enterProductStep}>
				<span>{labels.ScanOrEnterTheQrCodeOfThePointWhereYouAreCurrentlyWorking()}</span>
				<TextBoxWithQrScanner 
					name="qrCode" 
					label={labels.ProvideSalePointCode()} 
					control={control} 
					onQrScanned={onQrScanned} 
					showScannerByDefault 
					validationRule={{required: validationMessages.Required()}}
					submitAfterScan
				/>
			</div>
			<Button onClick={onSubmit}>{labels.Next().toLowerCase()}</Button>
		</>
	);
};

export default memo(EnterSalePoint);
