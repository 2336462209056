import React, { Ref, useState } from "react";
import ReactSelect, {
  ContainerProps,
  ControlProps,
  GroupBase,
  OptionProps,
} from "react-select";
import styles from "./Select.module.scss";
import clsx from "clsx";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import TextBox from "../TextBox/TextBox";

export type SelectProps = {
  label?: string | React.ReactElement;
  hasError?: boolean;
  refs?: any;
  isReadOnly?: boolean;
  value?: any;
} & StateManagerProps;

const Select: React.FC<SelectProps> = ({
  label,
  hasError,
  refs,
  isClearable = true,
  ...props
}) => {
  const classNames = {
    container: (state: ContainerProps<unknown, boolean, GroupBase<unknown>>) =>
      styles.container,
    control: (state: ControlProps) => clsx(styles.select, state.isFocused && styles.focused),
    menuPortal: () => styles.menuPortal,
    menu: () => styles.menu,
    input: () => styles.input,
    valueContainer: () => styles.valueContainer,
    singleValue: () => styles.value,
    placeholder: () => styles.placeholder,
    option: (state: OptionProps<unknown, boolean, GroupBase<unknown>>) =>
      clsx(
        styles.option,
        state.isFocused && styles.optionFocused,
        state.isSelected && styles.optionSelected,
        state.isDisabled && styles.optionDisabled
      ),
  };

  const [focus, setFocus] = useState(false);

  const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    if (props.onFocus) props.onFocus(e);
  };
  const _onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    if (props.onBlur) props.onBlur(e);
  };

  const hasValue = props.value != null || props.defaultValue != null;

  if (props.isReadOnly) {
    return <TextBox label={label} value={props.value?.value} isReadOnly={true} />;
  }

  return (
    <div
      className={clsx(
        styles.selectContainer,
        focus && styles.focused,
        hasValue && styles.hasValue,
        hasError && styles.validationError,
        props.isDisabled && styles.disabled
      )}
    >
      {label && <label>{label}</label>}
      <ReactSelect
        {...props}
        ref={refs}
        classNames={{ ...classNames }}
        menuPortalTarget={document.body}
        onFocus={_onFocus}
        onBlur={_onBlur}
        isClearable={isClearable}
      />
    </div>
  );
};

export default Select;
