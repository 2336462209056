import React, { ReactElement, memo } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ButtonLink.module.scss";
import { clsx } from 'clsx';

type ButtonLinkProps = {
	children?: ReactElement | string;
	link?: string;
	onClick?: () => void;
	refs?: React.Ref<HTMLDivElement>;
	className?: string;
} & (WithLink | WithOnClick);

type WithLink = {
	link: string;
};

type WithOnClick = {
	onClick: () => void;
};
const ButtonLink: React.FC<ButtonLinkProps> = ({ link, children, onClick, refs, className }) => {
	const navigate = useNavigate();
	const _onClick = () => {
		if (link) navigate(link);
		if (onClick) onClick();
	};
	return (
		<div className={clsx(styles.button, className)} onClick={_onClick} ref={refs}>
			<span>
				{children}
				<div className={styles.underline} />
			</span>
		</div>
	);
};

export default memo(ButtonLink);
