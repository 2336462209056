
export const formatFieldLabel = (props: any, rules: any): React.ReactElement => {
  return <>{props?.label}{rules?.required && !props?.isReadOnly && !!props?.label ? "*" : ""}</>;
}
export const formatPhoneNumber = (phoneNumber: string) => {
  var formattedPhoneNumber = phoneNumber.substring(1);
  formattedPhoneNumber = formattedPhoneNumber.replaceAll(" ", "");
  return formattedPhoneNumber;
}

export const formatPhoneNumberToInputFormat = (phoneNumber: string) => {
  var formatted = "48";
  for (let i = 3; i < phoneNumber.length + 1; i++) {
    const num = phoneNumber[i - 1];

    if (i % 3 == 0) {
      formatted += " " + num;
    }
    else {
      formatted += num;
    }
  }


  return "+" + formatted
}