import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { permissionsSelector } from "../../common/commonSlice";

type Props = {
  element?: React.ReactNode;
  requiredPermission?: string;
};

export default function GuardedRoute({ element, requiredPermission }: Props): any {
  const userPermissions = useAppSelector(permissionsSelector);
  const hasPermission = !requiredPermission || userPermissions?.find((p) => p === requiredPermission) !== undefined;
  
  var refreshTokenExpirationTimeStr = localStorage.getItem("refreshTokenExpire");
  if (!refreshTokenExpirationTimeStr || !hasPermission) {
    return <Navigate to="/login" replace={true} />;
  }

  var refreshTokenExpirationTime = new Date(refreshTokenExpirationTimeStr);
  const isValid = refreshTokenExpirationTime > new Date();
  return isValid ? element : <Navigate to="/login" replace={true} />;
}
