import React, { memo } from "react";
import Content from "../../components/ui/Content/Content";
import { SvgIcon } from "../../components/ui/SvgIcon/SvgIcon";
import { offerSaleSteps } from "./OfferSale";
import EnterSalePoint from "./Steps/EnterSalePoint";
import { Control, FieldErrors, UseFormSetValue } from "react-hook-form";
import { OfferSaleType } from "../../common/dataTypes/OfferSaleType";
import styles from "./OfferSale.module.scss";
import EnterPhoneNumber from "./Steps/EnterPhoneNumber";
import SelectOffer from "./Steps/SelectOffer";
import WaitForRegistration from "./Steps/WaitForRegistration";
import SaleConfirm from "./Steps/SaleConfirm";
import ScanDevice from "./Steps/ScanDevice";

import langs from "../../common/services/languageService";
import SaleSucessful from "./Steps/SaleSucessful";
import SaleFailed from "./Steps/SaleFailed";
import NoOffer from "./Steps/NoOffer";
import { Offer } from "../../common/models/Offer";
const { labels } = langs;

interface OfferSaleUIProps {
  onGoBack?: () => void;
  formStep?: offerSaleSteps;
  control: Control<OfferSaleType, any>;
  onQrScanned: (controlName: string, value: string, submitAfterScan?: boolean) => void;
  onSubmit: () => void;
  additionalData: any;
  setValue: UseFormSetValue<OfferSaleType>;
  onGetCustomerRegistrationStatus: () => void;
  offers: Offer[];
}

const OfferSaleUI: React.FC<OfferSaleUIProps> = ({
  onGoBack,
  formStep,
  control,
  onQrScanned,
  onSubmit,
  additionalData,
  setValue,
  onGetCustomerRegistrationStatus,
  offers
}) => {
  return (
    <Content title={labels.OfferSale()} icon={<SvgIcon icon={"user"} />} onGoBack={onGoBack}>
      <form className={styles.form} onSubmit={onSubmit}>
        {formStep === offerSaleSteps.qrSalePointVerification && (
          <EnterSalePoint control={control} onQrScanned={onQrScanned} onSubmit={onSubmit} />
        )}
        {formStep === offerSaleSteps.phoneNumberVerification && (
          <EnterPhoneNumber control={control} onSubmit={onSubmit} />
        )}
        {formStep === offerSaleSteps.selectOffer && (
          <SelectOffer control={control} additionalData={additionalData} onSubmit={onSubmit} setValue={setValue} offers={offers} />
        )}
        {formStep === offerSaleSteps.noOffer && <NoOffer control={control} onSubmit={onSubmit} />}
        {formStep === offerSaleSteps.waitForRegistration && (
          <WaitForRegistration
            onAbortProcess={onSubmit}
            onGetCustomerRegistrationStatus={onGetCustomerRegistrationStatus}
            additionalData={additionalData}
          />
        )}
        {formStep === offerSaleSteps.scanDevice && (
          <ScanDevice control={control} onQrScanned={onQrScanned} onSubmit={onSubmit} />
        )}
        {formStep === offerSaleSteps.saleConfirmation && <SaleConfirm onSubmit={onSubmit} />}
        {formStep === offerSaleSteps.saleSuccess && <SaleSucessful onSubmit={onSubmit} />}
        {formStep === offerSaleSteps.saleFailed && <SaleFailed onSubmit={onSubmit} />}
      </form>
    </Content>
  );
};

export default memo(OfferSaleUI);
