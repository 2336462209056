import { memo } from "react";

import styles from "./ValidationLabel.module.scss";
import { FieldError } from "react-hook-form";
import clsx from "clsx";

export type ValidationLabelProps = {
  className?: string;
  error?: FieldError;
  asBigText?: boolean;
  centered?: boolean;
};

const ValidationLabel: React.FC<ValidationLabelProps> = ({ className, error, asBigText, centered }) => {
  return <>{error && <div className={clsx(styles.errorMessage, className && className, asBigText && styles.asBigText, centered && styles.centered)}>{error?.message}</div>}</>;
};

export default memo(ValidationLabel);
