import React, { memo } from 'react'
import { Control } from 'react-hook-form'
import styles from '../OfferSale.module.scss'
import langs from '../../../common/services/languageService'
import Button from '../../../components/ui/Button/Button'
import TextBoxField from '../../../components/form/TextBoxField'
import { OfferSaleType } from '../../../common/dataTypes/OfferSaleType'
import Link from '../../../components/ui/Link/Link'
import { getRegulationLink } from '../../../common/services/clerkService'
import { RegulationsTypes } from '../../../common/consts/RegulationsTypes'
import { phoneNumberRegex } from '../../../common/helpers/validationHelper'
const { labels, validationMessages } = langs

interface EnterPhoneNumberProps {
  control: Control<OfferSaleType, any>
  onSubmit: () => void
}

const EnterPhoneNumber: React.FC<EnterPhoneNumberProps> = ({
  control,
  onSubmit,
}) => {
  const validationRules = {
    phoneNumber: {
      required: validationMessages.Required(),
      pattern: {
        value: phoneNumberRegex,
        message: validationMessages.PhoneNumber(),
      },
    },
  }

  return (
    <>
      <div className={styles.enterPhoneNumber}>
        <TextBoxField
          name="phoneNumber"
          control={control}
          label={labels.PhoneNumber()}
          inputProps={{ inputMode: 'tel' }}
          rules={validationRules.phoneNumber}
          mask="+48 ___ ___ ___"
          maskReplacement={{ _: /\d/ }}
        />
        <div className={styles.additionalText}>
          <span>{labels.MakeSureYouHaveProvideTheCustomerWith()}</span>
          <Link
            download
            href={getRegulationLink({
              type: RegulationsTypes.informationClause,
              offerId: null,
            })}
          >
            {labels.InformationOnPersonalDataProtection()}
          </Link>
        </div>
      </div>
      <Button onClick={onSubmit}>{labels.Verify().toLowerCase()}</Button>
    </>
  )
}

export default memo(EnterPhoneNumber)
