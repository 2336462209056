import React, { memo } from "react";
import { SvgIcon } from "../../../components/ui/SvgIcon/SvgIcon";
import styles from "../OfferSale.module.scss";
import Button from "../../../components/ui/Button/Button";

import langs from "../../../common/services/languageService";
const { labels, validationMessages } = langs;

interface SaleSucessfulProps {
	onSubmit: () => void;
}

const SaleSucessful: React.FC<SaleSucessfulProps> = ({ onSubmit }) => {
	return (
		<>
			<div className={styles.saleSucessful}>
				<div>
					<SvgIcon icon="circleCheck" animation height="150px" customColor="green" />
				</div>
				<div>{labels.SaleSuccessfulMessage()}</div>
				<div>{labels.SaleSuccessfullAdditionalWarning()}</div>
			</div>
			<Button onClick={onSubmit}>{labels.Close().toLowerCase()}</Button>
		</>
	);
};

export default memo(SaleSucessful);
