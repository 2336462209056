import { Info, InfoType } from "../../components/ui/Info/Info";
import Button from "../../components/ui/Button/Button";
import styles from "./InfoPage.module.scss";

import langs from "../../common/services/languageService";
const { labels } = langs;

export function InfoPage() {
  return (
    <Info hasIcon={true} header={labels.OfferSale()} buttons={<Button>{labels.AbortProcess().toLowerCase()}</Button>} type={InfoType.success}>
      <div className={styles.infoContainer}>
        <div>{labels.SellOfferProcessInProgress()}</div>
      </div>
    </Info>
  );
}
