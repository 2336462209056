import Button from "../../../components/ui/Button/Button";
import langs from "../../../common/services/languageService";
import styles from "./Greeting.module.scss";
import { redirect, useNavigate } from "react-router-dom";
import Content from "../../../components/ui/Content/Content";
import { SvgIcon } from "../../../components/ui/SvgIcon/SvgIcon";
import { Footer } from "../../../components/ui/Footer/Footer";
import { Portal } from "../../../components/ui/Portal/Portal";
import Image from "../../../components/ui/Image/Image";
import ploom_device from "../../../styles/images/ploom_device.png";

const { labels } = langs;

type Props = {
	onLoginClick: () => void;
};

export function Greeting(props: Props) {
	const navigate = useNavigate();

	return (
		<Content
			className={styles.greeting_content}
			// icon={<SvgIcon icon={"user"} />}
			// title={labels.Logging()}
		>
			<div className={styles.greeting}>
				<div className={styles.greeting_upperText}>
					<h1 className={styles.greeting_upperText_title}>{labels.Welcome()}</h1>
					<p className={styles.greeting_upperText_text}
						dangerouslySetInnerHTML={{
							__html: labels.LoginPageGreetingsContinuation(),
						}}
					/>
				</div>
				<div className={styles.greeting_form}>
					<div className={styles.logIn}>
						<p>{labels.LoginPageLoginOrRegister()}</p>
						<Button buttonType="primary" onClick={() => props.onLoginClick()} size="md" type="button">
							{labels.LogIn().toLowerCase()}
						</Button>
					</div>
					<div className={styles.register}>
						<p>{labels.DoYouHaveAccount()}</p>
						<Button buttonType="secondary" onClick={() => navigate("/register")} size="sm" type="button">
							{labels.Register().toLowerCase()}
						</Button>
					</div>
				</div>
			</div>
			<Footer />
			<Portal>
				<Image src={ploom_device} className={styles.img} />
			</Portal>
		</Content>
	);
}
