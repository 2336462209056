import React, { memo, useState } from "react";
import styles from "./ButtonWithConfirmation.module.scss";
import Button, { ButtonProps } from "../Button/Button";
import { Portal } from "../Portal/Portal";
import clsx from "clsx";

type ButtonWithConfirmationProps = {
	confirmationMessage?: string;
	confirmLabel?: string;
	cancelLabel?: string;
	onConfirm: () => void;
	onCancel?: () => void;
} & ButtonProps;

const ButtonWithConfirmation: React.FC<ButtonWithConfirmationProps> = ({ children, confirmationMessage = "Are you sure?", confirmLabel = "Yes", cancelLabel = "No", onConfirm, onCancel, ...other }) => {
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [showData, setShowData] = useState(false);

	const _onHideModal = () => {
		setShowConfirmationModal(false);
		setTimeout(() => {
			setShowData(false);
		}, 500);
	};

	const _onClickButton = () => {
		setShowData(true);
		setShowConfirmationModal(true);
	};

	const _onConfirm = () => {
		_onHideModal();
		if (onConfirm) onConfirm();
	};

	const _onCancel = () => {
		_onHideModal();
		if (onCancel) onCancel();
	};

	return (
		<>
			<Button type="button" {...other} onClick={_onClickButton}>
				{children}
			</Button>
			<Portal>
				<div className={clsx(styles.confirmationModal, showConfirmationModal && styles.showModal)}>
					{showData && (
						<>
							<div className={styles.message}>{confirmationMessage}</div>
							<div className={styles.buttons}>
								<Button  onClick={_onConfirm}>{confirmLabel.toLowerCase()}</Button>
								<Button buttonType="secondary" onClick={_onCancel}>{cancelLabel.toLowerCase()}</Button>
							</div>
						</>
					)}
				</div>
			</Portal>
		</>
	);
};

export default memo(ButtonWithConfirmation);
