import React, { ReactElement, memo, useEffect, useRef } from "react";
import styles from "./AnimationBlock.module.scss";
import { clsx } from "clsx";

type AnimationBlockProps = {
	animation?: Animations;
	animationDuration?: string;
	animationDelay?: string;
	children: (boolean | ReactElement | undefined)[] | ReactElement | boolean | undefined;
	slideSize?: string;
	animate?: boolean;
};

type Animations = AnimationsWithNoSlides | AnimationsWithSlides;

type AnimationsWithSlides = "fade-in-left" | "fade-in-right" | "fade-in-top" | "fade-in-bottom";
type AnimationsWithNoSlides = "fade-in";

const AnimationBlock: React.FC<AnimationBlockProps> = ({animate=true, animation = "fade-in", animationDuration = "1s", animationDelay = "0", children, slideSize="20px" }) => {
	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (!!animation && ref?.current) {
			ref.current.style.setProperty("--animationName", animation);
			if (!!slideSize) ref.current.style.setProperty("--slideSize", slideSize);
			if (!!animationDuration && ref?.current) ref.current.style.setProperty("--animationDuration", animationDuration);
			if (!!animationDelay && ref?.current) ref.current.style.setProperty("--animationDelay", animationDelay);
		}
	}, [animation, animationDelay, animationDuration, slideSize]);

	return (
		<div ref={ref} className={clsx(styles.container, animate && styles.animate)}>
			{children}
		</div>
	);
};

export default memo(AnimationBlock);
