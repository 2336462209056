import clsx from "clsx";
import React, { MouseEvent, ReactElement, memo, useState } from "react";
import styles from "./TextBox.module.scss";
import { InputMask, Replacement } from "@react-input/mask";

export type TextBoxProps = {
  id?: string;
  className?: string;
  onMouseDown?: (ev: MouseEvent<any>, state: { isFocused?: boolean }) => void;
  onChange?: (value: string) => void;
  onFocus?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  value?: string;
  disabled?: boolean;
  isReadOnly?: boolean;
  label?: string | ReactElement;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  additionalElement?: ReactElement | ReactElement[];
  refs?:
    | React.RefObject<HTMLInputElement>
    | React.RefCallback<HTMLInputElement>;
  mask?: string;
  maskReplacement?: Replacement | string;
  showMask?: boolean;
};

const TextBox: React.FC<TextBoxProps> = ({
  id,
  className,
  refs,
  onChange,
  onMouseDown,
  hasError,
  value,
  disabled,
  isReadOnly,
  label,
  mask,
  maskReplacement = " ",
  showMask = true,
  inputProps,
  onFocus,
  onBlur,
  additionalElement,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const _onMouseDown = (e: React.MouseEvent<any>) => {
    if (onMouseDown) onMouseDown(e, { isFocused: isFocused });
    e.currentTarget.focus();
  };

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value);
  };

  const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if ((!value || value == "") && mask && mask != "" && onChange) {
      onChange(mask);
      setTimeout(() => {
        if (inputProps?.inputMode == "tel")
          e.target.setSelectionRange(4, 4, "forward");
        else {
          e.target.setSelectionRange(0, 0, "forward");
        }
      }, 50);
    }
    if (onFocus) onFocus(e);
  };

  const _onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    const value = e.target.value == mask ? "" : e.target.value;
    if (onChange) onChange(value);
    if (onBlur) onBlur(e);
  };

  if (isReadOnly)
    return (
      <div className={clsx(styles.inputContainer, className, styles.readOnly)}>
        <span className={styles.label}>{label}</span>
        <span className={styles.value}>{value ? value : "-"}</span>
      </div>
    );

  return (
    <div
      className={clsx(
        styles.inputContainer,
        className,
        isFocused && styles.focused,
        disabled && styles.disabled,
        isReadOnly && styles.readOnly,
        hasError && styles.validationError,
        value && styles.hasValue
      )}
    >
      <div className={clsx(styles.inputWrapper)}>
      {mask ? (
        <InputMask
          mask={mask}
          replacement={maskReplacement}
          showMask={showMask}
          id={id}
          value={value || ""}
          className={styles.input}
          onChange={_onChange}
          ref={refs}
          disabled={disabled}
          onFocus={_onFocus}
          onBlur={_onBlur}
          onMouseDown={_onMouseDown}
          {...inputProps}
        />
      ) : (
        <input
          id={id}
          value={value || ""}
          className={styles.input}
          onChange={_onChange}
          ref={refs}
          disabled={disabled}
          onFocus={_onFocus}
          onBlur={_onBlur}
          onMouseDown={_onMouseDown}
          {...inputProps}
        />
      )}
      </div>

      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}

      {additionalElement ? (
        <div className={styles.additionalElement}>{additionalElement}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default memo(TextBox);
