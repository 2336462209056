import langs from "../../../common/services/languageService";
import styles from "./Login.module.scss";
import Button from "../../../components/ui/Button/Button";
import { useNavigate } from "react-router-dom";
import Content from "../../../components/ui/Content/Content";
import { SvgIcon } from "../../../components/ui/SvgIcon/SvgIcon";
import TextBoxField from "../../../components/form/TextBoxField";
import { SubmitHandler, useForm } from "react-hook-form";
import CheckboxField from "../../../components/form/CheckboxField";
import { emailRegex } from "../../../common/helpers/validationHelper";
import { login, refreshToken } from "../../../common/services/clerkService";
import { finishAsyncOperation, startAsyncOperation, setLoginData } from "../../../common/commonSlice";
import { useAppDispatch } from "../../../app/hooks";
import { Footer } from "../../../components/ui/Footer/Footer";
import PasswordInput from "../../../components/ui/PasswordInput/PasswordInput";
import { Portal } from "../../../components/ui/Portal/Portal";
import ploom_device from "../../../styles/images/ploom_device.png";
import Image from "../../../components/ui/Image/Image";
import AnimationBlock from "../../../components/ui/AnimationBlock/AnimationBlock";
import { ClerkTokenRequest } from "../../../common/models/ClerkTokenRequest";
import ReCaptchaField from "../../../components/form/ReCaptchaField";

const { labels, validationMessages } = langs;

type Props = {
  onLoginClick: () => void;
};

export type LoginFormValues = {
  email: string;
  password: string;
  rememberMe: boolean;
  captchaKey?: string;
};

export function Login(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors, isValid, isSubmitted },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    clearErrors,
    getValues,
    setError,
  } = useForm<LoginFormValues>({
    mode: "onChange",
    criteriaMode: "all",
  });
  const validationRules = {
    login: {
      required: validationMessages.Required(),
      pattern: {
        value: emailRegex,
        message: validationMessages.Email(),
      },
    },
    password: { required: validationMessages.Required() },
  };

  const onSubmit: SubmitHandler<LoginFormValues> = (data) => {
    dispatch(startAsyncOperation());

    login(data)
      .then((resp) => {
        var tokenRequest: ClerkTokenRequest = {
          ...resp.data,
          email: data.email,
          refreshToken: resp.data.refreshToken,
          rememberMe: data.rememberMe,
          userId: resp.data.userId,
        };

        refreshToken(tokenRequest).then((resp) => {
          dispatch(setLoginData(resp.data));
          localStorage.setItem("rememberMe", (!!data.rememberMe).toString());
          dispatch(finishAsyncOperation());
          navigate("/");
        });
      })
      .catch((err) => {
        dispatch(finishAsyncOperation());

        if (err.response.status == 401) {
          setError("password", {
            type: "custom",
            message: validationMessages.WrongEmailOrAddress(),
          });
          setValue("captchaKey", "");
        }
      });
  };

  return (
    <Content className={styles.login} icon={<SvgIcon icon={"user"} />} title={labels.Logging()}>
      <div className={styles.login_container}>
        <SvgIcon className={styles.ploomLogo} icon="logo" customColor="#fff" animation={true} animationDelay={300} />
        <form className={styles.login_form} onSubmit={handleSubmit(onSubmit)}>
          <AnimationBlock animationDelay="0.5s">
            <TextBoxField
              name="email"
              control={control}
              rules={validationRules.login}
              label={labels.EmailShort()}
              inputProps={{
                autoComplete: "email",
                type: "email",
                inputMode: "email",
              }}
            />
            <PasswordInput
              name="password"
              control={control}
              rules={validationRules.password}
              label={labels.Password()}
              passwordType="current-password"
            />
            <CheckboxField
              control={control}
              name="rememberMe"
              id="rememberMe"
              label={labels.RememberMe()}
              labelAlign="right"
            />

            <a onClick={() => navigate("/forgot-password")}>{labels.ForgotPassword()}</a>
          </AnimationBlock>

          <div className={styles.buttons}>
            <AnimationBlock animationDelay="0.7s">
              <Button buttonType="primary" type="submit">
                {labels.LogIn().toLowerCase()}
              </Button>
            </AnimationBlock>
            <AnimationBlock animationDelay="0.9s">
              <div>{labels.DoYouHaveAccount()}</div>
              <Button buttonType="secondary" onClick={() => navigate("/register")} type="button" size="sm">
                {labels.Register().toLowerCase()}
              </Button>
            </AnimationBlock>
          </div>
          <AnimationBlock animationDelay="1.1s">
            <ReCaptchaField control={control} name="captchaKey" className={styles.recaptcha} />
          </AnimationBlock>
        </form>
      </div>
      <Footer />
      <Portal>
        <Image src={ploom_device} className={styles.img} />
      </Portal>
    </Content>
  );
}
