import React, { memo } from "react";
import { SvgIcon } from "../../../components/ui/SvgIcon/SvgIcon";
import styles from "../OfferSale.module.scss";
import Button from "../../../components/ui/Button/Button";
import { offerSaleErrors } from "../OfferSale";
import ValidationLabel from "../../../components/ui/ValidationLabel/ValidationLabel";
import { FieldError } from "react-hook-form";

import langs from "../../../common/services/languageService";
const { labels } = langs;

interface SaleFailedProps {
	onSubmit: () => void;
}

const SaleFailed: React.FC<SaleFailedProps> = ({ onSubmit }) => {
	const serverError = offerSaleErrors.value.root?.serverError;
	return (
		<>
			<div className={styles.saleFailed}>
				<div>
					<SvgIcon icon="circleXMark" animation height="100px" customColor="red" />
				</div>
				<div>{labels.SaleFailedMessage()}</div>
				<ValidationLabel asBigText centered error={serverError as FieldError} className={""} />
			</div>
			<Button onClick={onSubmit}>{labels.Close().toLowerCase()}</Button>
		</>
	);
};

export default memo(SaleFailed);
