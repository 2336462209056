import React, { ReactElement, memo } from 'react';
import styles from './FormLayout.module.scss';
import { clsx } from 'clsx';

export type RowProps = {
    children?: (boolean | ReactElement | string )[] | ReactElement | boolean | string;
    renderIfEmpty?: boolean;
    className?: string;
}

const Row: React.FC<RowProps> = ({children, renderIfEmpty, className}) => {
    if(!renderIfEmpty && !children) return <></>
    return (
        <div className={clsx(styles.row, className)}>
            {children}
        </div>
    );
}

export default memo(Row);