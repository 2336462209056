import React, { ChangeEvent, memo } from "react";
import styles from "./RadioButtonList.module.scss";
import RadioButtonField from "./RadioButtonField";
import clsx from "clsx";
import { RadioButtonProps } from "../ui/RadioButton/RadioButton";

export type RadioButtonListOption = {
	value: string | number | readonly string[] | undefined;
	id?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	label: string | React.ReactElement;
	refs?: any;
	name: string;
	hasError?: boolean;
	checked?: boolean;
};

export type RadioButtonListProps = {
	name: string;
	control: any;
	rules?: any;
	layout?: "horizontal" | "vertical";
	options: RadioButtonListOption[];
    disabled? : boolean;
};

const RadioButtonList: React.FC<RadioButtonListProps> = ({ control, name, options, layout = "vertical", rules, disabled }) => {
	return (
		<div className={clsx(styles.radioButtonList, layout && styles[layout])}>
			{options.map((x, index) => (
				<RadioButtonField {...x} key={`${name}_option_${index}`} control={control} name={name} rules={rules} disabled={disabled} />
                // TODO hubertp: should we disabled all field or by option.disabled ? - to consider
			))}
		</div>
	);
};

export default memo(RadioButtonList);
